/* eslint react/prop-types: 0 */
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Group,
  ScrollArea,
  LoadingOverlay, Modal,
  Progress,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Table,
  Text,
  Tooltip
} from '@mantine/core'
import {
  IconBoxMultiple,
  IconChevronDown,
  IconChevronUp,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconRefresh,
  IconSelector,
  IconSettings,
  IconSettingsAutomation,
  IconSettingsOff,
  IconArrowAutofitRight,
  IconArrowAutofitLeft
} from '@tabler/icons-react'
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as DOMPurify from 'dompurify';
import { useTable } from 'react-table'
import Dropdown from '../Dropdown'
import * as queryBarClasses from './QueryBar.module.scss'
import * as styles from './ReactTable.module.scss'
import FilterControl from './Toolbar/FilterControl'
import Paginator from './Toolbar/Paginator'
import SearchBar from './Toolbar/SearchBar'
import FormFilters from './FormFilters';
import { createFormActions } from '@mantine/form';
import {
  paramsDictChanged,
  getFiltersFromDict,
  areSetsEqual,
  getNonNullParamsFromDict
} from './util';
import { useDispatch, useSelector } from 'react-redux';
import { useNamespacedStableSearchParams } from '../useStableSearchParams';
import {
  batchUpdateParams,
  segmentParams,
  selectParamsFilters,
  selectParamsIndexes,
  selectParamsLoaded,
  updateParamField,
  updateQuery
} from './paramsSlice';
import { useStableLocalStorage } from '../useStableLocalStorage';
import {
  NamespaceContext,
  TableCollapseContext,
  TableColumnsContext,
  TableControlContext,
  TableDataContext,
  TableFiltersContext,
  TableFiltersLoadedContext,
  TableStylesContext
} from './TableContexts';
import { useDisclosure, useHover } from '@mantine/hooks';
import { getRedactedRezview, getRezview } from '../../../js/api/applicant_repository';
import { showNotification } from '@mantine/notifications';
import { selectIsRowSelected } from './tableSelectedRowsSlice';
import { NewDropdown } from '../NewDropdown';
import RezviewSkeleton from '../../hire/cycle/RezviewSkeleton'

const PAGE_LIMIT_SIZES = ['20', '50', '100', '200']
const DEFAULT_PAGE_LIMIT = '50'

/**
 * React table wrapper for all non-data table contexts.
 */
export function ReactTableContextsProvider ({ columns, namespace = '', defaultFilters = {}, defaultHiddenColumns = [], children }) {
  const [initialDefaultFilters] = useState(defaultFilters ?? {})
  const [initialHiddenColumnDefaults] = useState(new Set(defaultHiddenColumns ?? []))
  return (
    <NamespaceContext.Provider value={namespace}>
      <ReactTableColumnsContextProvider columns={columns} defaultHiddenColumns={initialHiddenColumnDefaults}>
        <ReactTableCollapseContextProvider columns={columns} >
          <ReactTableFiltersContextProvider defaultFilters={initialDefaultFilters}>
            <ReduxTableControlContextProvider>
              {children}
            </ReduxTableControlContextProvider>
          </ReactTableFiltersContextProvider>
        </ReactTableCollapseContextProvider>
      </ReactTableColumnsContextProvider>
    </NamespaceContext.Provider>
  )
}

/**
 * React table wrapper for save/clear filters and default filters functionality.
 */
export function ReactTableFiltersContextProvider ({ defaultFilters = {}, children }) {
  const namespace = useContext(NamespaceContext)
  const [loadedFilters, setLoadedFilters] = useState(false)
  const dispatch = useDispatch()
  const [searchParams] = useNamespacedStableSearchParams(namespace)
  const initialSearchParamsRef = useRef(searchParams)
  const savedFiltersConfig = {
    key: location.pathname + '-saved-table-filters-' + (namespace ?? ''),
    defaultValue: defaultFilters,
    serialize: (value) => JSON.stringify(value),
    deserialize: (str) => str === undefined ? defaultFilters : JSON.parse(str)
  }
  const [savedFilters, setSavedFilters, resetSavedFilters] = useStableLocalStorage(savedFiltersConfig)
  const initialFiltersRef = useRef(savedFilters)
  const loadedFiltersRef = useRef(false)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))

  useEffect(() => {
    if (!loadedFiltersRef.current) {
      const initialFilters = initialFiltersRef.current
      loadedFiltersRef.current = true
      if (Object.keys(initialFilters).length && !Object.keys(getFiltersFromDict(initialSearchParamsRef.current)).length && !initialSearchParamsRef.current.page && !loaded) {
        const { filters: parsedFilters, indexes: parsedIndexes, meta: parsedMeta } = segmentParams(initialFilters)
        console.debug('Updating initial redux query params from default/saved filters', { parsedFilters, parsedIndexes, parsedMeta, initialFilters })
        dispatch(updateQuery({
          queryId: namespace,
          params: {
            indexes: { limit: parseInt(DEFAULT_PAGE_LIMIT), page: 1, search: '', ...parsedIndexes },
            filters: parsedFilters,
            meta: parsedMeta
          }
        }))
      } else if (!loaded) {
        const { filters: parsedFilters, indexes: parsedIndexes, meta: parsedMeta } = segmentParams(initialSearchParamsRef.current)
        console.debug('Updating redux query params from pre-existing filters in url', { parsedFilters, parsedIndexes, parsedMeta })
        dispatch(updateQuery({
          queryId: namespace,
          params: {
            indexes: { limit: parseInt(DEFAULT_PAGE_LIMIT), page: 1, search: '', ...parsedIndexes },
            filters: parsedFilters,
            meta: parsedMeta
          }
        }))
      } else {
        console.debug('Other filters applied or no default filters - skipping redux update.', initialFilters)
      }
      setLoadedFilters(true)
    }
  }, [namespace, loaded, dispatch])

  const showSaveFilters = useMemo(() => {
    const filters = getFiltersFromDict(searchParams)
    return paramsDictChanged(filters, getFiltersFromDict(savedFilters))
  }, [savedFilters, searchParams])

  const showClearSavedFilters = useMemo(() => {
    return paramsDictChanged(defaultFilters, savedFilters)
  }, [savedFilters, defaultFilters])

  const clearSavedFilters = useCallback(() => {
    console.debug('Clearing saved filters')
    resetSavedFilters()
  }, [resetSavedFilters])

  const saveFilters = useCallback(() => {
    console.debug('Saving filters', searchParams)
    setSavedFilters(getFiltersFromDict(searchParams))
  }, [searchParams, setSavedFilters])

  const currentTableFiltersContext = useMemo(() => {
    console.info('Updating table filters context memo.', { savedFilters, showSaveFilters, showClearSavedFilters })
    return {
      defaultFilters: savedFilters,
      clearSavedFilters: clearSavedFilters,
      saveFilters: saveFilters,
      showSaveFilters: showSaveFilters,
      showClearSavedFilters: showClearSavedFilters
    }
  }, [savedFilters, clearSavedFilters, saveFilters, showSaveFilters, showClearSavedFilters])
  console.debug('Table filters provider updated.', { savedFilters, showSaveFilters, showClearSavedFilters, loadedFilters })

  return (
    <TableFiltersLoadedContext.Provider value={loadedFilters}>
      <TableFiltersContext.Provider value={currentTableFiltersContext}>
        {children}
      </TableFiltersContext.Provider>
    </TableFiltersLoadedContext.Provider>
  )
}

function getNestedToggleVisibilityColumns (columns) {
  const returnColumns = []
  for (const column of columns) {
    if (column.hideable) {
      returnColumns.push(column)
    }
    if (column.columns?.length) {
      returnColumns.push(...getNestedToggleVisibilityColumns(column.columns))
    }
  }
  return returnColumns
}

export function ReactTableColumnsContextProvider ({ columns, defaultHiddenColumns = new Set(), children }) {
  const namespace = useContext(NamespaceContext)
  const savedColumnsConfig = {
    key: location.pathname + '-saved-table-hidden-columns-' + (namespace ?? ''),
    defaultValue: defaultHiddenColumns,
    serialize: (value) => JSON.stringify([...value]),
    deserialize: (str) => str === undefined ? new Set(defaultHiddenColumns) : new Set(JSON.parse(str))
  }
  const [savedColumns, setSavedColumns, resetSavedColumns] = useStableLocalStorage(savedColumnsConfig)
  const [hiddenColumns, setHiddenColumns] = useState(savedColumns)
  const hiddenColumnsWhenReset = useMemo(() => [...savedColumns], [savedColumns])

  const toggleableVisibilityColumns = useMemo(() => {
    return getNestedToggleVisibilityColumns(columns)
  }, [columns])

  const updateHiddenColumns = useCallback((columnId, visible) => {
    console.debug('Updating hidden column state', columnId, visible)
    if (visible === null && columnId === null) { // Reset
      setHiddenColumns(new Set(savedColumns))
    } else if (columnId === null) { // Show/Hide All
      setHiddenColumns(new Set(visible ? [] : toggleableVisibilityColumns.map(column => column.id)))
    } else {
      setHiddenColumns((prev) => {
        const newHidden = new Set(prev)
        if (visible) {
          newHidden.delete(columnId)
        } else {
          newHidden.add(columnId)
        }
        return newHidden
      })
    }
  }, [toggleableVisibilityColumns, savedColumns])

  const showSaveColumns = useMemo(() => {
    return !areSetsEqual(hiddenColumns, savedColumns)
  }, [hiddenColumns, savedColumns])

  const showClearSavedColumns = useMemo(() => {
    return !areSetsEqual(defaultHiddenColumns, savedColumns)
  }, [savedColumns, defaultHiddenColumns])

  const clearSavedColumns = useCallback(() => {
    console.debug('Clearing saved hidden columns')
    resetSavedColumns()
  }, [resetSavedColumns])

  const saveColumns = useCallback(() => {
    console.debug('Saving hidden columns')
    setSavedColumns(hiddenColumns)
  }, [hiddenColumns, setSavedColumns])

  const currentTableColumnsContext = useMemo(() => {
    console.info('Updating table columns context memo.', { columns, hiddenColumns, hiddenColumnsWhenReset })
    return {
      columns: columns,
      toggleableVisibilityColumns: toggleableVisibilityColumns,
      defaultHiddenColumns: hiddenColumnsWhenReset,
      hiddenColumnsSet: hiddenColumns,
      onColumnVisibilityChange: updateHiddenColumns,
      saveColumns: saveColumns,
      clearSavedColumns: clearSavedColumns,
      showSaveColumns: showSaveColumns,
      showClearSavedColumns: showClearSavedColumns
    }
  }, [columns, toggleableVisibilityColumns, hiddenColumnsWhenReset, hiddenColumns, updateHiddenColumns, saveColumns, clearSavedColumns, showSaveColumns, showClearSavedColumns])
  console.debug('Table columns provider updated.', { columns, hiddenColumns, hiddenColumnsWhenReset })

  return (
    <TableColumnsContext.Provider value={currentTableColumnsContext}>
      {children}
    </TableColumnsContext.Provider>
  )
}

export const TableHiddenColumnSaveControls = memo(function TableHiddenColumnSaveControl () {
  const { saveColumns, clearSavedColumns, showSaveColumns, showClearSavedColumns } = useContext(TableColumnsContext)
  return (
    <Group justify='center'>
      <Tooltip label={showClearSavedColumns ? 'Clear Saved Hidden Columns' : 'No Hidden Columns Saved'}>
        <ActionIcon variant='subtle' aria-label='Clear Saved Hidden Columns' onClick={clearSavedColumns} disabled={!showClearSavedColumns} color='orange'>
          <IconSettingsOff />
        </ActionIcon>
      </Tooltip>
      <Tooltip label={showSaveColumns ? 'Save Hidden Columns' : 'No Changes to Save'}>
        <ActionIcon variant='subtle' aria-label='Save Hidden Columns' onClick={saveColumns} disabled={!showSaveColumns} color='teal'>
          <IconSettingsAutomation />
        </ActionIcon>
      </Tooltip>
    </Group>
  )
})

function createCollapsedColumnMap (columns) {
  const columnMap = new Map()
  const collapseChildren = new Map()
  for (const column of columns) {
    if (column.collapsedGroup) {
      columnMap.set(column.collapsedGroup, column.id)
    }
    if (column.columns?.length) {
      const [recursiveColumnMap, recursiveCollapseChildren] = createCollapsedColumnMap(column.columns)
      for (const [key, value] of recursiveColumnMap.entries()) {
        columnMap.set(key, value)
      }
      if (column.collapsable) {
        const currentCollapseChildren = [...column.columns.map(elem => elem.id)]
        for (const collapseGrandchildren of recursiveCollapseChildren.values()) {
          currentCollapseChildren.push(...collapseGrandchildren)
        }
        collapseChildren.set(column.id, currentCollapseChildren)
      } else {
        for (const [collapseId, collapseGrandchildren] of recursiveCollapseChildren.entries()) {
          collapseChildren.set(collapseId, collapseGrandchildren)
        }
      }
    }
  }
  return [columnMap, collapseChildren]
}

function getFlatDefaultCollapsed (collapseChildren, collapseGroupId, includeCollapseGroup = false) {
  const flatDefaultCollapsed = new Set()
  console.debug('Collapse group id', { collapseGroupId, collapseChildren, includeCollapseGroup })
  for (const collapseChildId of collapseChildren.get(collapseGroupId)) {
    if (collapseChildren.has(collapseChildId)) {
      for (const recursiveCollapseChildId of getFlatDefaultCollapsed(collapseChildren, collapseChildId, includeCollapseGroup)) {
        flatDefaultCollapsed.add(recursiveCollapseChildId)
      }
      if (includeCollapseGroup) {
        flatDefaultCollapsed.add(collapseChildId)
      }
    } else {
      flatDefaultCollapsed.add(collapseChildId)
    }
  }
  return [...flatDefaultCollapsed]
}

export function ReactTableCollapseContextProvider ({ columns, children }) {
  const [[collapseMap, collapseChildren]] = useState(createCollapsedColumnMap(columns))
  const [collapsed, setCollapsed] = useState(new Set([...collapseMap.keys()]))

  const summaryColumnIds = useMemo(() => {
    return [...collapseMap.values()]
  }, [collapseMap])

  const expandedColumnGroupIds = useMemo(() => {
    return [...collapseMap.keys()]
  }, [collapseMap])

  const updateCollapsedColumns = useCallback((collapseGroupId, collapsed) => {
    console.debug('Updating collapsed column state', collapseGroupId, collapsed)
    if (collapsed === null && collapseGroupId === null) { // Reset
      setCollapsed(new Set(expandedColumnGroupIds))
    } else if (collapseGroupId === null) { // Expand/Collapse All
      setCollapsed(new Set(collapsed ? expandedColumnGroupIds : summaryColumnIds))
    } else {
      setCollapsed((prev) => {
        const newCollapsed = new Set(prev)
        if (collapsed) {
          newCollapsed.delete(collapseMap.get(collapseGroupId))
          newCollapsed.add(collapseGroupId)
        } else {
          newCollapsed.delete(collapseGroupId)
          newCollapsed.add(collapseMap.get(collapseGroupId))
        }
        return newCollapsed
      })
    }
  }, [expandedColumnGroupIds, summaryColumnIds, collapseMap])

  const defaultCollapsed = useMemo(() => {
    const flatDefaultCollapsed = new Set()
    for (const defaultCollapseGroupId of expandedColumnGroupIds) {
      for (const collapseChildId of getFlatDefaultCollapsed(collapseChildren, defaultCollapseGroupId)) {
        flatDefaultCollapsed.add(collapseChildId)
      }
    }
    return [...flatDefaultCollapsed]
  }, [expandedColumnGroupIds, collapseChildren])

  const currentTableCollapseContext = useMemo(() => {
    console.info('Updating table collapse context memo.', { collapsed })
    const expandedGroupSummaries = new Set() // TODO determine if any parent groups not collapsed - if all collapsed, don't render first header groups
    const knownCollapsedChildren = new Set()
    for (const [collapseGroupId, collapseSummaryId] of collapseMap.entries()) {
      if (collapsed.has(collapseGroupId) || knownCollapsedChildren.has(collapseGroupId)) {
        for (const collapseChild of getFlatDefaultCollapsed(collapseChildren, collapseGroupId, true)) {
          knownCollapsedChildren.add(collapseChild)
        }
      } else {
        expandedGroupSummaries.add(collapseSummaryId)
      }
    }
    const expandedSummaries = [...expandedGroupSummaries].filter(elem => !knownCollapsedChildren.has(elem)) // Insert order should prevent this filter from being needed, but that's an implementation detail outside of this function's scope.
    return {
      collapsed: collapsed,
      onCollapseChange: updateCollapsedColumns,
      defaultCollapsed: defaultCollapsed,
      collapseMap: collapseMap,
      collapseChildren: collapseChildren,
      expandedSummaries: expandedSummaries
    }
  }, [collapsed, updateCollapsedColumns, collapseMap, collapseChildren, defaultCollapsed])
  console.debug('Table collapse provider updated.', { columns, collapsed, collapseMap, collapseChildren, defaultCollapsed })

  return (
    <TableCollapseContext.Provider value={currentTableCollapseContext}>
      {children}
    </TableCollapseContext.Provider>
  )
}

/**
 * Redux updates wrapper.
 */
export function ReduxTableControlContextProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const defaultFiltersLoaded = useContext(TableFiltersLoadedContext)
  const dispatch = useDispatch()
  const filters = useSelector(state => selectParamsFilters(state, namespace))
  const indexes = useSelector(state => selectParamsIndexes(state, namespace))
  const [, setSearchParams] = useNamespacedStableSearchParams(namespace)

  useEffect(() => {
    if (!defaultFiltersLoaded) {
      console.debug('Skipping search param sync until all filters loaded.', { filters, indexes, defaultFiltersLoaded })
      return
    }
    console.debug('Syncing search params from change in filters or indexes.', { filters, indexes })
    setSearchParams(getNonNullParamsFromDict({ ...indexes, ...(filters ?? {}) }))
  }, [filters, indexes, setSearchParams, defaultFiltersLoaded])

  const updateSearchParamField = useCallback((field, value) => {
    dispatch(updateParamField({ queryId: namespace, field: field, value: value }))
  }, [namespace, dispatch])

  const batchUpdateSearchParams = useCallback((fieldsToValues) => {
    dispatch(batchUpdateParams({ queryId: namespace, fieldsToValues: fieldsToValues }))
  }, [namespace, dispatch])

  const activeFilters = filters ?? null

  const currentTableControlContext = useMemo(() => {
    console.info('Updating table control context memo.', { activeFilters, indexes })
    return {
      updateSearchParamField: updateSearchParamField,
      batchUpdateSearchParams: batchUpdateSearchParams,
      activeFilters: activeFilters ?? {},
      activeIndexes: indexes
    }
  }, [activeFilters, indexes, updateSearchParamField, batchUpdateSearchParams])
  console.debug('Table control provider updated.', { activeFilters, indexes })

  return (
    <TableControlContext.Provider value={currentTableControlContext}>
      {children}
    </TableControlContext.Provider>
  )
}

/**
 * @param actions
 * @param tableActions
 * @param tableActionsLabel
 * @param onRowClick
 * @param noBorders
 * @param searchable
 * @param notDynamic
 * @param filters
 * @param formFilters
 * @param miw
 * @param boxConfig
 * @param bulkActions
 * @param bulkActionsLabel
 */
export const ReactTable = memo(function ReactTable (
  {
    actions = [],
    tableActions = [],
    tableActionsLabel = 'Actions',
    onRowClick = null,
    noBorders = false,
    searchable,
    notDynamic,
    formFilters = [],
    filters = {},
    miw = 500,
    boxConfig = null,
    abstractOrderVariants = null,
    alwaysShowHeaderGroups = true,
    showHeaderGroupsIfExpanded = true,
    bulkActions = [],
    bulkActionsLabel
  }
) {
  const namespace = useContext(NamespaceContext)
  const { defaultFilters } = useContext(TableFiltersContext)
  const { columns, toggleableVisibilityColumns, defaultHiddenColumns, hiddenColumnsSet, onColumnVisibilityChange } = useContext(TableColumnsContext)
  const { collapsed, defaultCollapsed, onCollapseChange, collapseMap, collapseChildren, expandedSummaries } = useContext(TableCollapseContext)
  const { updateSearchParamField, batchUpdateSearchParams, activeFilters, activeIndexes } = useContext(TableControlContext)
  const { collection, querying } = useContext(TableDataContext)
  const search = activeIndexes.search ?? ''
  const limitPotentiallyString = activeIndexes.limit ?? DEFAULT_PAGE_LIMIT
  const limit = (typeof limitPotentiallyString === 'string' || limitPotentiallyString instanceof String) ? parseInt(limitPotentiallyString) : limitPotentiallyString
  const pagePotentiallyString = activeIndexes.page ?? 1
  const page = (typeof pagePotentiallyString === 'string' || pagePotentiallyString instanceof String) ? parseInt(pagePotentiallyString) : pagePotentiallyString
  const orderBy = activeIndexes.order_by ?? undefined
  const orderDirection = activeIndexes.order_direction ?? undefined
  const total = collection?.total ?? 0
  const hasTableActions = tableActions.length > 0
  const lastAbstractSortRef = useRef(null)
  const lastAbstractSortId = !!orderBy && !!abstractOrderVariants && (activeFilters[abstractOrderVariants[orderBy] ?? '_neverMatch_'] ?? false) ? activeFilters[abstractOrderVariants[orderBy]] : null
  const formFiltersAction = createFormActions('react-table-form-filters')
  const defaultHiddenAndCollapsedColumns = useMemo(() => {
    return [...(new Set([...defaultCollapsed, ...defaultHiddenColumns]))]
  }, [defaultCollapsed, defaultHiddenColumns])

  console.debug('React table updating', { defaultHiddenAndCollapsedColumns, defaultHiddenColumns, defaultCollapsed, collapsed, collapseChildren, expandedSummaries, lastAbstractSortId, orderBy, abstractOrderVariants, activeFilters })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    toggleHideColumn
  } = useTable(
    {
      columns: columns,
      data: collection?.items ?? [],
      initialState: {
        hiddenColumns: defaultHiddenAndCollapsedColumns
      }
    }
  )

  console.debug('React table headerGroups', { headerGroups, columns })

  const boxParams = useMemo(() => {
    const cursorParams = querying ? { style: { cursor: 'progress' } } : {}
    if (boxConfig) {
      if (boxConfig.style && querying) {
        return { miw: miw, ...boxConfig, style: { ...boxConfig.style, cursor: 'progress' } }
      }
      return { miw, ...boxConfig, ...cursorParams }
    }
    return { miw, ...cursorParams }
  }, [miw, boxConfig, querying])

  const updateSort = column => {
    if (!column.sortable) return
    const newOrderBy = column.sortId ?? column.id
    const newOrderDirection = (((column.abstractOrder ?? newOrderBy) === orderBy) && (!column.abstractOrder || ((lastAbstractSortId === newOrderBy && !lastAbstractSortRef.current) || (lastAbstractSortRef.current === `${column.abstractOrder}=${newOrderBy}`)))) ? (orderDirection === 'ASC' ? 'DESC' : 'ASC') : (column.defaultSortOrder ?? 'ASC')
    if (column.abstractOrder) {
      batchUpdateSearchParams({ order_by: column.abstractOrder, [abstractOrderVariants[column.abstractOrder]]: newOrderBy, order_direction: newOrderDirection })
      lastAbstractSortRef.current = `${column.abstractOrder}=${newOrderBy}`
    } else {
      lastAbstractSortRef.current = null
      batchUpdateSearchParams({ order_by: newOrderBy, order_direction: newOrderDirection })
    }
  }

  const updateCollapse = (collapseGroupId, collapseState, column) => {
    toggleHideColumn(collapseMap.get(collapseGroupId))
    for (const collapseChildId of collapseChildren.get(collapseGroupId)) {
      toggleHideColumn(collapseChildId)
    }
    onCollapseChange(collapseGroupId, collapseState)
  }

  const resetSort = () => {
    batchUpdateSearchParams({
      ...Object.fromEntries(Object.entries(activeFilters).map(([key, value], index) => [key, undefined])),
      ...defaultFilters,
      order_by: undefined,
      order_direction: undefined,
      page: 1,
      limit: parseInt(DEFAULT_PAGE_LIMIT),
      search: '',
      ...(abstractOrderVariants ? Object.fromEntries(Object.entries(abstractOrderVariants).map(([orderByFieldValue, abstractOrderByField]) => [abstractOrderByField, undefined])) : {})
    })
    setHiddenColumns(defaultHiddenAndCollapsedColumns)
    onCollapseChange?.(null, null)
    onColumnVisibilityChange?.(null, null)
    formFiltersAction.reset()
  }

  const PageControls = () => {
    const pageTotal = limit ? Math.ceil(total / limit) : 1
    return (
      <Flex justify='flex-end' gap='xs'>
        {!notDynamic && pageTotal > 1 && <Paginator total={pageTotal} page={page} onUpdate={(value) => updateSearchParamField('page', value)} />}
        <Tooltip label='Number of rows'>
          <Select
            value={limit.toString()}
            onChange={(value) => updateSearchParamField('limit', value ? parseInt(value) : null)}
            data={PAGE_LIMIT_SIZES}
            w={70}
            miw={60}
            size='xs'
          />
        </Tooltip>
      </Flex>
    )
  }

  const isColumnVisible = (columnId) => {
    return !hiddenColumnsSet.has(columnId)
  }

  const isAllColumnsVisible = () => !hiddenColumnsSet.size

  const TableActionsButton = (
    <Button leftSection={<IconSettings />} variant='filled'>{tableActionsLabel}</Button>
  )

  const BulkActionsButton = (
    <Button leftSection={<IconBoxMultiple />} variant='filled'>{bulkActionsLabel}</Button>
  )

  const ToggleColumnsButton = (
    <ActionIcon color='gray' title='Show/Hide columns'>
      <IconEye />
    </ActionIcon>
  )

  const hideableColumns = toggleableVisibilityColumns

  return (
    <Box {...boxParams}>
      <SimpleGrid cols={1} verticalSpacing='xs'>
        {!!(formFilters?.length) && (
          <FormFilters
            namespace={namespace}
            formFilters={formFilters}
            onSubmit={batchUpdateSearchParams}
          />
        )}
        <Group position='apart'>
          {filters.length > 0 && <FilterControl filters={filters} selected={activeFilters} onChange={batchUpdateSearchParams} />}
          {
            hasTableActions &&
            <Dropdown target={TableActionsButton} items={tableActions} />
          }
          {
            bulkActions.length > 0 &&
            <Dropdown target={BulkActionsButton} items={bulkActions} />
          }
        </Group>
        {
          !notDynamic &&
          <Group justify='space-between'>
            <Group spacing='xs'>
              {searchable && <SearchBar value={search} onChange={(value) => updateSearchParamField('search', value)} />}
              <Tooltip label='Reset table'>
                <ActionIcon onClick={() => resetSort()} variant='subtle' color='gray'><IconRefresh /></ActionIcon>
              </Tooltip>
              {hideableColumns.length > 0 &&
                <Dropdown target={ToggleColumnsButton} extraAlwaysOpen items={hideableColumns.map(column => {
                  const targetColumnVisible = isColumnVisible(column.id)
                  return {
                    label: column.headerLabel ?? column.Header.props?.children ?? 'N/A',
                    c: targetColumnVisible ? 'var(--mantine-color-text)' : 'dimmed',
                    onClick: () => {
                      console.debug('Called on click for column visibility', column.id, column)
                      if (column.collapsedGroup && collapsed.has(column.id)) {
                        for (const collapseChildId of collapseChildren.get(column.collapsedGroup)) {
                          toggleHideColumn(collapseChildId)
                        }
                      } else if (collapsed.has(column.id) && collapseChildren.has(column.id)) {
                        toggleHideColumn(collapseMap.get(column.id))
                      } else {
                        toggleHideColumn(column.id)
                      }
                      onColumnVisibilityChange?.(column.id, !targetColumnVisible)
                    },
                    leftSection: targetColumnVisible ? <IconEye /> : <IconEyeOff />
                  }
                })}>
                  <Flex justify='center' mb='sm'>
                    <Button
                      disabled={isAllColumnsVisible()}
                      onClick={() => {
                        setHiddenColumns(defaultCollapsed)
                        onCollapseChange?.(null, true)
                        onColumnVisibilityChange?.(null, true)
                      }}
                      variant='light'
                      size='compact-sm'
                    >
                      Show All
                    </Button>
                  </Flex>
                  <TableHiddenColumnSaveControls />
                </Dropdown>
              }
            </Group>
            <Flex justify='flex-end' align='center' gap='xs'>
              {total > 0 && <Badge color='blue' variant='outline' size='md'>Showing {Math.max(page * limit - (limit - 1), total ? 1 : 0)}-{limit ? Math.min(limit * page, total) : total} of {total}</Badge>}
              {!notDynamic && <PageControls />}
            </Flex>
          </Group>
        }
        <TopHorizontalScrollbar>
          <QueryBar querying={querying} />
          <Table
            {...getTableProps()}
            withRowBorders
            withColumnBorders={!noBorders}
            className={styles.table}
            data-hover={!!onRowClick}
          >
            <Table.Thead>
              {headerGroups.filter((headerGroup, index, allHeaderGroups) => (index === (allHeaderGroups.length - 1)) || (!!alwaysShowHeaderGroups) || (!!showHeaderGroupsIfExpanded && !!expandedSummaries.length)).map((headerGroup, headerGroupIndex) => (
                <Table.Tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup_${headerGroupIndex}`}>
                  {headerGroup.headers.map((column) => (
                    <TableHeader
                      {...column.getHeaderProps()}
                      key={column.id}
                      column={column}
                      updateSort={updateSort}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                      lastAbstractSortId={lastAbstractSortId}
                      collapsed={collapsed}
                      updateCollapse={updateCollapse}
                      />
                  ))}
                  {actions.length > 0 && <th></th>}
                </Table.Tr>
              ))}
            </Table.Thead>
            <Table.Tbody {...getTableBodyProps()} className={onRowClick ? styles.selectableRows : ''}>
              {rows.map((row) => {
                prepareRow(row)
                return <TableRow
                  {...row.getRowProps()}
                  key={row.original.id}
                  row={row}
                  actions={actions}
                  noBorders={noBorders}
                  onRowClick={onRowClick}
                  hiddenColumns={hiddenColumnsSet}
                  collapsed={collapsed}
                />
              })}
              {((rows.length === 0) && !querying) ? <Table.Tr><Table.Td colSpan={columns.length + 1}><Text>No rows found</Text></Table.Td></Table.Tr> : null}
            </Table.Tbody>
          </Table>
        </TopHorizontalScrollbar>
        {((rows.length === 0) && querying) ? <Box pos='relative' mih='5rem' w='100%' h='100%' bg='gray.2'><LoadingOverlay zIndex={200} visible={querying} overlayProps={{ blur: 4, backgroundOpacity: 0.6 }} /></Box> : null}
        <Flex justify='flex-end' align='center' gap='xs'>
          {total > 0 && <Badge color='blue' variant='outline' size='md'>Showing {Math.max(page * limit - (limit - 1), total ? 1 : 0)}-{limit ? Math.min(limit * page, total) : total} of {total}</Badge>}
          {!notDynamic && <PageControls />}
        </Flex>
      </SimpleGrid>
    </Box>
  )
})

const scrollViewportProps = { style: { transform: 'rotateX(180deg)', overflowX: 'auto' } }
const scrollBoxProps = { style: { transform: 'rotateX(180deg)' } }

function TopHorizontalScrollbar ({ children }) {
  return (
    <Box w='100%' {...scrollBoxProps}>
      <ScrollArea w='100%' scrollbars='x' viewportProps={scrollViewportProps}>
        <Box pos='relative'>
          {children}
        </Box>
      </ScrollArea>
    </Box>
  )
}

export default ReactTable

function QueryBar ({ querying }) {
  const BAR_WIDTH = 30
  return (
    <Box className={queryBarClasses.wrapper}>
      {querying &&
        <Progress.Root classNames={queryBarClasses}>
          <span className={queryBarClasses.sectionWrapper}>
          <Progress.Section striped value={BAR_WIDTH} color='blue' />
          </span>
        </Progress.Root>
      }
    </Box>
  )
}

const RowActionsButton = (
  <ActionIcon color='gray' size='lg'>
    <IconDotsVertical />
  </ActionIcon>
)

const MultipleSortPopover = memo(function MultipleSortPopover ({ column, updateSort, orderBy, orderDirection }) {
  return (
    <NewDropdown
      withinPortal={false}
      target={(
      <ActionIcon color='gray' size='xs'>
        {column.sortingAccessors?.map(sort => sort.id).includes(orderBy)
          ? orderDirection === 'ASC'
            ? <IconChevronDown />
            : <IconChevronUp />
          : <IconSelector />
        }
      </ActionIcon>
    )}
    openDelay={150}
    closeDelay={300}
  >
    <Stack align='center' justify='center'>
      {column.sortingAccessors?.map((sort, index) => {
        sort.sortable = true
        return (
          <Stack key={sort.id} justify='center' align='center' w='100%'>
            <Text>{sort.label}</Text>
            <ActionIcon color='gray' variant={orderBy !== sort.id && 'subtle'} onClick={() => updateSort(sort)}>
              {orderBy === sort.id
                ? orderDirection === 'ASC'
                  ? <IconChevronDown />
                  : <IconChevronUp />
                : <IconSelector />
              }
            </ActionIcon>
          </Stack>
        )
      })}
    </Stack>
  </NewDropdown>
  )
})

const TableHeader = memo(function TableHeader ({ column, updateSort, orderBy, orderDirection, collapsed, updateCollapse, lastAbstractSortId, ...headerProps }) {
  const { hovered, ref } = useHover()

  const headerPadding = useMemo(() => {
    const PADDING_SIZE = 'lg'

    if (!column.sortable) return {}
    if (column.align === 'center') return { px: PADDING_SIZE }
    return { pr: PADDING_SIZE }
  }, [column.align, column.sortable])

  return (
    <Table.Th
      {...headerProps}
      ref={ref}
    >
      {(!!column.summaryColumnGroup || !!column.collapsedGroup) && (
        <Space h='md' />
      )}
      <Flex align='center' justify='space-between' pos='relative' w='100%' {...headerPadding}>
        {column.render('Header')}
        <Box hidden={!hovered && !column.sortingAccessors?.map(sort => sort.id).includes(orderBy) && ((lastAbstractSortId ?? orderBy) !== (column.sortId ?? column.id))} pos='absolute' right='-0.5rem'>
        {column.sortable && (column.sortingAccessors
          ? <MultipleSortPopover column={column} updateSort={updateSort} orderBy={orderBy} orderDirection={orderDirection} />
          : <ActionIcon color='gray' variant='subtle' size='xs' onClick={() => updateSort(column)}>
              {(lastAbstractSortId ?? orderBy) === (column.sortId ?? column.id)
                ? orderDirection === 'ASC'
                  ? <IconChevronUp />
                  : <IconChevronDown />
                : <IconSelector />
              }
            </ActionIcon>
        )}
        </Box>
      </Flex>
      {(!!column.summaryColumnGroup || !!column.collapsedGroup) && (
        <Group justify='center' wrap='nowrap' my={0} py={0}>
          {!!column.summaryColumnGroup && (
            <ActionIcon size='xxs' onClick={() => updateCollapse(column.summaryColumnGroup ?? column.originalId, !collapsed.has(column.summaryColumnGroup ?? column.originalId), column)}>
              {collapsed.has(column.summaryColumnGroup ?? column.originalId)
                ? <IconArrowAutofitRight />
                : <IconArrowAutofitLeft />
              }
            </ActionIcon>
          )}
          {!!column.collapsedGroup && (
            <ActionIcon size='xxs' onClick={() => updateCollapse(column.collapsedGroup, !collapsed.has(column.collapsedGroup))}>
              {collapsed.has(column.collapsedGroup)
                ? <IconArrowAutofitRight />
                : <IconArrowAutofitLeft />
              }
            </ActionIcon>
          )}
        </Group>
      )}
    </Table.Th>
  )
})

const TableRow = memo(function TableRow ({ row, actions = [], noBorders, onRowClick, collapsed, hiddenColumns, ...props }) {
  const isSelected = useSelector(state => selectIsRowSelected(state, row.original.id))
  const { generateRowStyles } = useContext(TableStylesContext)

  const rowStyles = useMemo(() => {
    const _styles = {}

    if (isSelected) {
      _styles.bg = 'blue.1'
    }

    if (generateRowStyles) {
      return generateRowStyles(row.original, _styles)
    }

    return _styles
  }, [isSelected, generateRowStyles, row.original])

  return (
    <Table.Tr
      {...props}
      onClick={e => {
        if (window.getSelection().toString().length === 0) { onRowClick?.(e.currentTarget) }
      }}
      data-rowid={`${row.original.id}`}
      {...rowStyles}
      >
      {row.cells.map(cell => {
        if (hiddenColumns.has(cell.column.id)) {
          return null
        }
        return <Table.Td {...cell.getCellProps()} key={`cell_${cell.row.id}_${cell.column.id}`}>
            {cell.render('Cell')}
        </Table.Td>
      })}
      {actions.length > 0 &&
        <Table.Td role='cell'>
          <ClickContainer className={styles.actionsCell} align={noBorders ? 'flex-end' : 'center'}>
            <Dropdown target={RowActionsButton} items={actions} row={row.original} openDelay={300} closeDelay={150} />
          </ClickContainer>
        </Table.Td>
      }
    </Table.Tr>
  )
})

export function ClickContainer ({ children, className = [], align }) {
  if (children.length === 0) return null
  return (
    <Flex className={[className, styles.clickContainer]} direction='column' align={align ?? 'center'} onClick={e => e.stopPropagation()}>{children}</Flex>
  )
}

export function Header ({ children, centered, ...props }) {
  return (
    <Flex
      w='100%'
      fw='630'
      fz='md'
      c='gray.7'
      justify={centered ? 'center' : 'flex-start'}
      ta={centered ? 'center' : 'left'}
      {...props}
    >
      {children}
    </Flex>
  )
}

export function NumberCell ({ centered = false, children, wrapperProps = {}, ...props }) {
  const content = useMemo(() => (
    <Flex
      direction='column'
      align='flex-end'
      ta='right'
      {...props}
    >
      {children}
    </Flex>
  ), [children, props])

  if (centered) {
    return (
      <Flex
        justify='center'
        align='center'
        {...wrapperProps}
      >
        {content}
      </Flex>
    )
  }

  return content
}

export function RezviewCell ({ name, applicantId, address = null, isRedacted = false, isAdmin = false, ...props }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [content, setContent] = useState('');
  const sanitizedContent = useMemo(() => DOMPurify.sanitize(content), [content])

  const handleRezviewClick = (event) => {
    event.stopPropagation()
    open()
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal

    if (opened) {
      const rezview = (!isAdmin && isRedacted)
        ? getRedactedRezview(applicantId, signal)
        : getRezview(applicantId, signal)

      rezview
        .then(response => setContent(response))
        .catch((err) => {
          console.error('Error fetching rezview:', err.response)

          showNotification({
            title: 'Something went wrong',
            message: 'There was an error fetching the rezview',
            color: 'red',
            autoClose: 3000
          })
        })
    }

    return () => {
      controller.abort();
    }
  }, [opened, isAdmin, applicantId, isRedacted]);

  return (
    <>
      <Flex direction='column' justify='center' align='flex-start' {...props}>
        <Button onClick={handleRezviewClick} variant='transparent' p={0} size='compact-md'>
          {name}
        </Button>
      {!!address && <Text ta='left' size='sm' c='dimmed'>{address}</Text>}
      </Flex>
      <Modal opened={opened} onClose={close} onClick={e => e.stopPropagation()} transitionProps={{ transition: 'slide-up' }} size='65rem'>
        {sanitizedContent !== '' && <div dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>}
        {sanitizedContent === '' && <RezviewSkeleton />}
      </Modal>
    </>
  )
}
