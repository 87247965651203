/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSelectedValues,
  selectHasAnyRowsSelected, unselectAll
} from '../../core/ReactTable/tableSelectedRowsSlice';
import {
  ActionIcon,
  Affix,
  Box,
  Button,
  Center,
  Checkbox,
  Grid,
  Group,
  Modal,
  Paper,
  rem,
  ScrollArea,
  Select,
  SimpleGrid,
  Space,
  Tabs,
  Text,
  Textarea,
  Title,
  Tooltip,
  Transition
} from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';
import {
  useBulkClearScoresMutation,
  useBulkCommentMutation,
  useEditStatusMutation
} from '../../../redux/query/hire/applicantsApi.slice';
import { notifications } from '@mantine/notifications';
import { IconMessageChatbot, IconStatusChange, IconRefreshAlert, IconClipboardOff, IconFileLike } from '@tabler/icons-react';
import NoticeAlert from '../../core/Alert/NoticeAlert';
import { useCycleStatuses, useCycleTableModuleInfos } from './CycleHooks';
import dayjs from 'dayjs';
import { DatePicker } from '@mantine/dates';
import { CycleContext, NamespaceContext } from '../../core/ReactTable/TableContexts';
import BulkActionsController, {
  DEFAULT_BULK_ACTIONS_AFFIX_LEFT,
  DEFAULT_BULK_ACTIONS_AFFIX_TOP
} from '../../core/ReactTable/BulkActionsController';
import * as styles from '../../layout/animations/styles.module.css';

export const DetailViewSelectedHandler = memo(function DetailViewSelectedHandler ({ account }) {
  const namespace = useContext(NamespaceContext)
  const anySelected = useSelector(state => selectHasAnyRowsSelected(state, namespace))
  const [dragging, setDragging] = useState(false)

  console.debug('DetailViewSelectedHandler updating', { anySelected, account })

  const canEditStatus = !!(account.access.DEVELOPER || account.access.ADMIN || account.access.PORTAL_CLIENT)
  const canClearScores = !!(account.access.DEVELOPER || account.access.ADMIN)
  return (
    <>
      {!!anySelected && (
        <Affix className={styles.bulkAffixContainer} position={{ top: `${DEFAULT_BULK_ACTIONS_AFFIX_TOP}vh`, left: `${DEFAULT_BULK_ACTIONS_AFFIX_LEFT}vw` }}>
          <Transition transition='slide-up' duration={150} exitDuration={50} mounted={!!anySelected} keepMounted>
            {(transitionStyles) => (
              <Box style={transitionStyles}>
                <BulkActionsController setDragging={setDragging}>
                  {!!canEditStatus && (<BulkStatusControl disabled={dragging} />)}
                  <BulkCommentControl disabled={dragging} />
                  {!!canClearScores && (<BulkClearScoresControl disabled={dragging} />)}
                </BulkActionsController>
              </Box>
            )}
          </Transition>
        </Affix>
      )}
    </>
  )
})

const noStatusOptions = []
const hiredTierId = 2

const BulkStatusControl = memo(function BulkStatusControl ({ disabled }) {
  const dispatch = useDispatch()
  const cycleId = useContext(CycleContext)
  const namespace = useContext(NamespaceContext)
  const selectedApplicants = useSelector(state => selectAllSelectedValues(state, namespace))
  const [opened, { open, close }] = useDisclosure(false)
  const [dateModalOpened, { open: openDateModal, close: closeDateModal }] = useDisclosure(false)
  const [currentValue, setCurrentValue] = useState(null)
  const [statuses] = useCycleStatuses(cycleId)
  const [edit, { isLoading: processing }] = useEditStatusMutation()

  const statusOptions = useMemo(() => {
    if (!statuses?.items?.length) {
      return noStatusOptions
    }
    return statuses.items.map(status => ({ value: status.name, label: status.name, meta: status }))
  }, [statuses])

  const clearAndClose = useCallback(() => {
    setCurrentValue(null)
    closeDateModal()
    close()
  }, [close, closeDateModal])

  const onModalClose = useCallback(() => {
    console.debug('Called bulk status modal close')
    closeDateModal()
    setCurrentValue(null)
  }, [closeDateModal])

  const onModalSave = useCallback((hiredDate) => {
    console.debug('Called bulk status modal save', { hiredDate, currentValue, selectedApplicants })
    edit({
      status: currentValue,
      applicants: selectedApplicants.map(row => row.id),
      hiredDate: hiredDate,
      cycleId: cycleId
    })
      .unwrap()
      .then(() => {
        notifications.show({
          title: 'Bulk Status Success',
          message: 'The selected candidates have all been updated with their new status!',
          autoClose: 5000,
          color: 'green'
        })
        setCurrentValue(null)
        close()
        closeDateModal()
        dispatch(unselectAll({ namespace }))
      })
      .catch((e) => {
        console.error('Bulk status error response', { e })
        notifications.show({
          title: 'Bulk Status Error',
          message: 'Failed to update status for selected candidates.',
          color: 'red',
          autoClose: 7000
        })
      })
  }, [currentValue, selectedApplicants, edit, cycleId, close, closeDateModal, dispatch, namespace])

  const onSelect = useCallback((newValue, selectedOption) => {
    console.debug('Called on bulk status select', { newValue, selectedOption, currentValue })
    if (newValue) {
      if (newValue !== currentValue) {
        setCurrentValue(newValue)
        if (selectedOption?.meta?.tier?.id === hiredTierId) {
          openDateModal()
        }
      }
    }
  }, [currentValue, openDateModal])

  console.debug('BulkStatusControl updating', { selectedApplicants, opened, dateModalOpened, currentValue, statusOptions })

  return (
    <div>
      <Center>
        <Tooltip label='Change Statuses' disabled={disabled}>
          <ActionIcon
            variant="outline"
            aria-label="Change Statuses"
            size="xl"
            disabled={!selectedApplicants.length || disabled}
            onClick={open}
            color='blue'
          >
            <IconStatusChange />
          </ActionIcon>
        </Tooltip>
      </Center>
      <Modal opened={opened} onClose={clearAndClose} title='Bulk Status Edit' size='xl'>
        <Box>
          <NoticeAlert title='Choose status for selected candidates below' />
          <Group justify='space-between' grow wrap='nowrap' align='flex-start'>
            <Select data={statusOptions} value={currentValue} onChange={onSelect} disabled={processing} />
            <SelectedApplicantsList selectedApplicants={selectedApplicants} />
          </Group>
          <Space h='md' />
          <Group justify='center' grow>
            <Button onClick={() => onModalSave('')} color='success' loading={processing} disabled={!currentValue}>Save</Button>
            <Button onClick={clearAndClose} color='gray' loading={processing}>Cancel</Button>
          </Group>
          <BulkStatusHiredDateModal opened={dateModalOpened} onClose={onModalClose} onSave={onModalSave} processing={processing} />
        </Box>
      </Modal>
    </div>
  )
})

function BulkStatusHiredDateModal ({ opened, onClose, onSave, processing = false }) {
  const [value, setValue] = useState(new Date())

  const onClick = () => {
    onSave(dayjs(value).local().format('YYYY-MM-DD'))
  }
  console.debug('StatusCellHiredDateModal updating', { value, opened })
  return (
    <Modal opened={opened} onClose={() => onClose(false)} title='Info Required!'>
      <Box>
        <NoticeAlert title='Please select the hire date' />
        <Center><DatePicker value={value} onChange={setValue} disabled={processing} /></Center>
        <Space h='md' />
        <Group justify='center' grow>
          <Button onClick={onClick} color='success' loading={processing}>Save</Button>
          <Button onClick={onClose} color='gray' loading={processing}>Cancel</Button>
        </Group>
      </Box>
    </Modal>
  )
}

const BulkCommentControl = memo(function BulkCommentControl ({ disabled }) {
  const dispatch = useDispatch()
  const cycleId = useContext(CycleContext)
  const namespace = useContext(NamespaceContext)
  const selectedApplicants = useSelector(state => selectAllSelectedValues(state, namespace))
  const [opened, { open, close }] = useDisclosure(false)
  const [content, setContent] = useState('')
  const [pinned, setPinned] = useState(true)
  const [bulkComment, { isLoading: processing }] = useBulkCommentMutation()

  const clearAndClose = useCallback(() => {
    setContent('')
    setPinned(true)
    close()
  }, [close])

  const save = useCallback(() => {
    bulkComment({ applicants: selectedApplicants.map(row => row.id), content: content, pinned: pinned, cycleId: cycleId })
      .unwrap()
      .then(() => {
        notifications.show({
          title: 'Bulk Comment Success',
          message: 'Your comment has been added to all selected candidates!',
          color: 'green',
          autoClose: 5000
        })
        setContent('')
        setPinned(true)
        close()
        dispatch(unselectAll({ namespace }))
      })
      .catch((e) => {
        console.error('Bulk comment error response', { e })
        notifications.show({
          title: 'Bulk Comment Error',
          message: 'Failed to comment selected candidates.',
          color: 'red',
          autoClose: 7000
        })
      })
  }, [bulkComment, selectedApplicants, content, pinned, cycleId, close, dispatch, namespace])

  console.debug('BulkCommentControl updating', { selectedApplicants, opened, content, pinned })

  return (
    <div>
      <Center>
        <Tooltip label='Bulk Comment' disabled={disabled}>
          <ActionIcon
            variant="outline"
            aria-label="Bulk Comment"
            size="xl"
            disabled={!selectedApplicants.length || disabled}
            onClick={open}
            color='blue'
          >
            <IconMessageChatbot />
          </ActionIcon>
        </Tooltip>
      </Center>
      <Modal opened={opened} onClose={clearAndClose} title='Bulk Comment' size='xl'>
        <Box>
          <NoticeAlert title='Comment selected candidates below' />
          <Group justify='space-between' grow wrap='nowrap' align='flex-start'>
            <Box>
              <Textarea
                label="Bulk Comment"
                value={content}
                onChange={(event) => setContent(event.currentTarget.value)}
                autosize
                minRows={5}
                maxRows={10}
              />
              <Space h='xs' />
              <Checkbox
                label='Pinned'
                checked={pinned}
                aria-label='Pin comment'
                onChange={(event) => setPinned(event.currentTarget.checked)}
                disabled={processing}
              />
            </Box>
            <SelectedApplicantsList selectedApplicants={selectedApplicants} />
          </Group>
          <Space h='md' />
          <Group justify='center' grow>
            <Button onClick={save} color='success' loading={processing} disabled={!content.trim()}>Save</Button>
            <Button onClick={clearAndClose} color='gray' loading={processing}>Cancel</Button>
          </Group>
        </Box>
      </Modal>
    </div>
  )
})

const BulkClearScoresControl = memo(function BulkClearScoresControl ({ disabled }) {
  const dispatch = useDispatch()
  const cycleId = useContext(CycleContext)
  const namespace = useContext(NamespaceContext)
  const selectedApplicants = useSelector(state => selectAllSelectedValues(state, namespace))
  const [modules, modulesQuerying, , modulesError] = useCycleTableModuleInfos(cycleId)
  const [opened, { open, close }] = useDisclosure(false)
  const [forceUsePassportSubmissions, setForceUsePassportSubmissions] = useState(false)
  const [manualClearConfirm, setManualClearConfirm] = useState(null)
  const [bulkClear, { isLoading: processing }] = useBulkClearScoresMutation()

  const clearAndClose = useCallback(() => {
    setForceUsePassportSubmissions(false)
    setManualClearConfirm(null)
    close()
  }, [close])

  const doSubmit = useCallback((clearedManualEdits = null) => {
    bulkClear({
      applicantIds: selectedApplicants.map(row => row.id),
      clearIds: clearedManualEdits ?? [],
      forceUsePassport: forceUsePassportSubmissions,
      cycleId: cycleId
    })
      .unwrap()
      .then(() => {
        notifications.show({
          title: 'Bulk Clear Scores Success',
          message: `All selected candidates have had their scores cleared! They will${forceUsePassportSubmissions ? ' not ' : ' '}prefer submissions from other cycles when available.`,
          color: 'green',
          autoClose: 5000
        })
        setForceUsePassportSubmissions(false)
        setManualClearConfirm(null)
        close()
        dispatch(unselectAll({ namespace }))
      })
      .catch((e) => {
        console.error('Bulk clear scores error response', { e })
        notifications.show({
          title: 'Bulk Clear Scores Error',
          message: 'Failed to clear scores for selected candidates.',
          color: 'red',
          autoClose: 7000
        })
      })
  }, [bulkClear, selectedApplicants, forceUsePassportSubmissions, cycleId, close, dispatch, namespace])

  const save = useCallback(() => {
    const [clearableManuallyEditedScores, neverClearedScores, applicantIdToApplicant] = parseManuallyEditedScores(modules, selectedApplicants)
    if (clearableManuallyEditedScores.size) {
      setManualClearConfirm({ clearable: clearableManuallyEditedScores, preserved: neverClearedScores, applicants: applicantIdToApplicant })
    } else {
      doSubmit()
    }
  }, [modules, selectedApplicants, doSubmit])

  console.debug('BulkClearScoresControl updating', { selectedApplicants, opened, forceUsePassportSubmissions })

  return (
    <div>
      <Center>
        <Tooltip label='Bulk Clear Scores' disabled={disabled}>
          <ActionIcon
            variant='outline'
            aria-label='Bulk Clear Scores'
            size='xl'
            disabled={!selectedApplicants.length || disabled}
            onClick={open}
            color='blue'
          >
            <IconRefreshAlert />
          </ActionIcon>
        </Tooltip>
      </Center>
      <Modal opened={opened} onClose={clearAndClose} title='Bulk Clear Scores' size='xl'>
        <Box>
          <NoticeAlert title='Pull in new scores for candidates below'>
            This will pull in the most recent battery scores for each candidate, even if those battery scores are from other cycles.
            Check the Use Passport Scores box to prefer passport submissions from this cycle where possible.
          </NoticeAlert>
          <Group justify='space-between' grow wrap='nowrap' align='flex-start'>
            <Box>
              <Checkbox
                label='Use Passport Scores'
                checked={forceUsePassportSubmissions}
                aria-label='Use Passport Scores'
                onChange={(event) => setForceUsePassportSubmissions(event.currentTarget.checked)}
                disabled={processing}
              />
            </Box>
            <SelectedApplicantsList selectedApplicants={selectedApplicants} />
          </Group>
          <Space h='md' />
          <Group justify='center' grow>
            {(!modulesQuerying && !modules?.items?.length)
              ? (
              <Tooltip label={modulesError ? 'Error fetching modules' : 'No modules found'}>
                <Button onClick={() => console.debug('Clicked disabled save button')} color='success' loading data-disabled>Save</Button>
              </Tooltip>
                )
              : (
              <Button onClick={save} color='success' loading={processing || modulesQuerying}>Save</Button>
                )}
            <Button onClick={clearAndClose} color='gray' disabled={processing}>Cancel</Button>
          </Group>
        </Box>
      </Modal>
      {!!manualClearConfirm && (
        <BulkClearManualEditedScoresModal
          manual={manualClearConfirm}
          submit={doSubmit}
          cancel={clearAndClose}
          disabled={processing}
        />
      )}

    </div>
  )
})

function BulkClearManualEditedScoresModal ({ manual, submit, cancel, disabled }) {
  const [savedScores, setSavedScores] = useState(new Set())

  const onSubmit = () => {
    const clearedScores = new Set()
    for (const applicantScores of manual.clearable.values()) {
      for (const score of applicantScores) {
        if (!savedScores.has(score.id)) {
          clearedScores.add(score.id)
        }
      }
    }
    console.debug('Bulk clear scores with manual edited manually filtered', { savedScores, clearedScores, manual })
    submit([...clearedScores.values()])
  }
  console.debug('BulkClearManualEditedScoresModal updating', { manual, disabled, savedScores })

  return (
    <div>
      <Modal opened={!!manual} onClose={cancel} title='Confirm Manual Scores' size='xl'>
        <Box>
          <NoticeAlert title='Confirm clearing manually edited scores for candidates below'>
            The following scores have been manually edited. Unselect a row via clicking to preserve a manually edited score.
          </NoticeAlert>
          <Group justify='space-between' grow wrap='nowrap' align='flex-start'>
            <ConfirmManualScoresApplicantList
              scores={manual.clearable}
              disabledScores={manual.preserved}
              applicants={manual.applicants}
              savedScores={savedScores}
              setSavedScores={setSavedScores}
            />
          </Group>
          <Space h='md' />
          <Group justify='center' grow>
            <Button onClick={onSubmit} color='success' loading={disabled}>Submit</Button>
            <Button onClick={cancel} color='gray' disabled={disabled}>Cancel</Button>
          </Group>
        </Box>
      </Modal>
    </div>
  )
}

const noDisabledScoresFallback = []

const ConfirmManualScoresApplicantList = memo(function ConfirmManualScoresApplicantList ({ scores, disabledScores, applicants, savedScores, setSavedScores }) {
  console.debug('ConfirmManualScoresApplicantList updating', { scores, disabledScores, savedScores, applicants })
  return (
    <Paper shadow="md" radius="md" p="md" maw='100%' withBorder>
      <Title order={5} ta='center'>Candidates&apos; Manual Scores</Title>
      <ScrollArea.Autosize mah='50vh' scrollbars='y' type='auto'>
        <Box pos='relative'>
          {[...scores.entries()].map(([applicantId, scores]) => (
            <ConfirmManualScoreApplicant
              key={applicantId}
              applicant={applicants.get(applicantId)}
              scores={scores}
              disabledScores={disabledScores.get(applicantId) ?? noDisabledScoresFallback}
              savedScores={savedScores}
              setSavedScores={setSavedScores}
            />
          ))}
        </Box>
      </ScrollArea.Autosize>
    </Paper>
  )
})

const listItemStyle = { userSelect: 'none', cursor: 'pointer' }
const iconStyle = { width: rem(12), height: rem(12) }

const ConfirmManualScoreApplicant = memo(function ConfirmManualScoreApplicant ({ applicant, scores, disabledScores, savedScores, setSavedScores }) {
  const lastName = (applicant.first_name && applicant.last_name) ? ' ' + applicant.last_name : (applicant.last_name ?? '')
  const applicantName = (applicant.first_name ?? '') + lastName
  const idToScore = useMemo(() => {
    const scoresMap = new Map()
    for (const score of scores) {
      scoresMap.set(score.id, score)
    }
    return scoresMap
  }, [scores])

  const [applicantsCleared, applicantsSaved] = useMemo(() => {
    const newCleared = []
    const newSaved = []
    for (const [scoreId, score] of idToScore.entries()) {
      if (savedScores.has(scoreId)) {
        newSaved.push(score)
      } else {
        newCleared.push(score)
      }
    }
    return [newCleared, newSaved]
  }, [idToScore, savedScores])

  console.debug('ConfirmManualScoreApplicant updating', { scores, disabledScores, savedScores, applicant, applicantsCleared, applicantsSaved })

  return (
    <div style={listItemStyle}>
      <Paper
        bg='gray.1'
        mt='xxs'
        mb={0}
        mx='sm'
        shadow='xs'
        radius='xs'
        p='sm'
      >
        <div>
          <Text ta='center' fw={750} size='md' truncate='end'>{applicantName}</Text>
        </div>
        <Tabs defaultValue='cleared' color='teal' variant='pills' keepMounted={false} allowTabDeactivation>
          <Tabs.List>
            <Tabs.Tab
              value='cleared'
              disabled={!applicantsCleared.length}
              leftSection={<IconClipboardOff style={iconStyle} />}
            >
              Cleared
            </Tabs.Tab>
            <Tabs.Tab
              value='saved'
              color='blue'
              disabled={!applicantsSaved.length && !disabledScores.length}
              leftSection={<IconFileLike style={iconStyle} />}
            >
              Preserved
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value='cleared'>
            <ManualScoreList
              scores={applicantsCleared}
              setSavedScores={setSavedScores}
              selected={true}
            />
          </Tabs.Panel>
          <Tabs.Panel value='saved'>
            <ManualScoreList
              scores={applicantsSaved}
              setSavedScores={setSavedScores}
              selected={false}
              disabledScores={disabledScores}
            />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </div>
  )
})

const ManualScoreList = memo(function ManualScoreList ({ scores, setSavedScores, selected, disabledScores = null }) {
  console.debug('ManualScoreList updating', { scores, selected, disabledScores })

  return (
    <div style={listItemStyle}>
      <Paper
        bg='gray.2'
        mt='xxs'
        mb={0}
        mx='sm'
        shadow='xs'
        radius='xs'
        p='sm'
      >
        <SimpleGrid cols={1} verticalSpacing={0}>
          {scores.map(score => (
            <ManualScoreListItem
              key={score.id}
              score={score}
              setSavedScores={setSavedScores}
              selected={selected}
              disabled={false}
            />
          ))}
          {!!disabledScores?.length && disabledScores.map(score => (
            <ManualScoreListItem
              key={score.id}
              score={score}
              setSavedScores={setSavedScores}
              selected={selected}
              disabled={true}
            />
          ))}
          {!scores.length && !disabledScores?.length && (
            <Box mih='3rem'>
              <Text ta='center'>No Matching Manual Scores</Text>
            </Box>
          )}
        </SimpleGrid>
      </Paper>
    </div>
  )
})

const ManualScoreListItem = memo(function ManualScoreListItem ({ score, setSavedScores, selected, disabled }) {
  const { hovered, ref } = useHover()

  console.debug('ManualScoreListItem updating', { score, selected, disabled })

  return (
    <div style={listItemStyle} ref={ref}>
      <Paper
        bg={selected ? (hovered ? 'gray.1' : 'gray.3') : ((hovered && !disabled) ? 'gray.1' : 'gray.3')}
        mt='xxs'
        mb={0}
        mx='sm'
        shadow='xs'
        radius='xs'
        p='sm'
        onClick={() => {
          if (disabled) {
            console.debug('Clicked disabled score', { score, selected })
            return
          }
          setSavedScores((prev) => {
            console.debug('Updating saved scores', { prev, score, selected })
            const newSaved = new Set(prev)
            if (selected) {
              newSaved.add(score.id)
            } else {
              newSaved.delete(score.id)
            }
            return newSaved
          })
        }}
      >
        <Grid columns={24} align='center'>
          <Grid.Col span={8}>
            <Tooltip label='Module'>
              <Text ta='left' fw={750} size='md' truncate='end'>{score.moduleName}</Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span={8}>
            <Tooltip label='Raw score'>
              <Text ta='left' size='sm' truncate='end'>{parseScoreDisplay(score.score)}</Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span={4}>
            <Tooltip label='Percentile score'>
              <Text ta='left' size='sm' truncate='end'>{parseScoreDisplay(score.percentile_score)}</Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span={4}>
            <Tooltip label='Z score'>
              <Text ta='left' size='sm' truncate='end'>{parseScoreDisplay(score.z_score)}</Text>
            </Tooltip>
          </Grid.Col>
        </Grid>
      </Paper>
    </div>
  )
})

function parseScoreDisplay (scoreValue) {
  return ((scoreValue ?? '') !== '' ? scoreValue : '-')
}

function parseManuallyEditedScores (modules, selectedApplicants) {
  const neverClearModuleIds = new Set([52]) // Legacy Courtesy Letter module id = 52. Other never-cleared module name = "Written Assessment"
  const moduleIdToName = new Map()
  for (const moduleInfo of modules.items) {
    if (moduleInfo.module?.name?.trim().toLowerCase() === 'written assessment') {
      neverClearModuleIds.add(moduleInfo.module.id)
    }
    if (moduleInfo.module?.id && moduleInfo.module?.name) {
      moduleIdToName.set(moduleInfo.module.id, moduleInfo.module.name)
    }
  }
  const neverClearedScores = new Map()
  const clearableManuallyEditedScores = new Map()
  const applicantIdToApplicant = new Map()
  for (const applicant of selectedApplicants) {
    applicantIdToApplicant.set(applicant.id, applicant)
    const applicantsNeverClearedScores = []
    const applicantsClearableManuallyEditedScores = []
    for (const score of applicant.scores) {
      if (neverClearModuleIds.has(score.module_id)) {
        applicantsNeverClearedScores.push({ ...score, moduleName: moduleIdToName.get(score.module_id ?? -1) ?? 'Not found' })
      } else if (score.manual_edit) {
        applicantsClearableManuallyEditedScores.push({ ...score, moduleName: moduleIdToName.get(score.module_id ?? -1) ?? 'Not found' })
      }
    }
    if (applicantsNeverClearedScores.length) {
      neverClearedScores.set(applicant.id, applicantsNeverClearedScores)
    }
    if (applicantsClearableManuallyEditedScores.length) {
      clearableManuallyEditedScores.set(applicant.id, applicantsClearableManuallyEditedScores)
    }
  }
  return [clearableManuallyEditedScores, neverClearedScores, applicantIdToApplicant]
}

const SelectedApplicantsList = memo(function SelectedApplicantsList ({ selectedApplicants }) {
  return (
    <Paper shadow="md" radius="md" p="md" maw='100%' withBorder>
      <Title order={5} ta='center'>Selected Candidates ({selectedApplicants.length})</Title>
      <ScrollArea.Autosize mah='50vh' scrollbars='y' type='auto'>
        <Box pos='relative'>
          {selectedApplicants.map((applicant) => <SelectedApplicantsListItem key={applicant.id} applicant={applicant} />)}
        </Box>
      </ScrollArea.Autosize>
    </Paper>
  )
})

const SelectedApplicantsListItem = memo(function SelectedApplicantsListItem ({ applicant }) {
  const { hovered, ref } = useHover()
  const lastName = (applicant.first_name && applicant.last_name) ? ' ' + applicant.last_name : (applicant.last_name ?? '')
  const applicantName = (applicant.first_name ?? '') + lastName

  return (
    <div style={listItemStyle} ref={ref}>
      <Paper
        bg={hovered ? 'gray.2' : 'gray.1'}
        mt='xxs'
        mb={0}
        mx='sm'
        shadow='xs'
        radius='xs'
        p='sm'
      >
        <Grid columns={24} align='center'>
          <Grid.Col span={15}>
            <Text ta='left' fw={750} size='md' truncate='end'>{applicantName}</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <Text ta='left' size='sm' truncate='end'>{applicant.status?.name ?? 'Status Missing'}</Text>
          </Grid.Col>
        </Grid>
      </Paper>
    </div>
  )
})
