/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useState, useRef, useEffect, useReducer } from 'react';
import { Button, Checkbox, Grid, Text, Flex, Center, ActionIcon, Tooltip, Popover, TextInput } from '@mantine/core';
import { QuestionStateUpdate } from './QuestionsState';
import { IconBan, IconCheck, IconX } from '@tabler/icons-react';
import { tagIconFromEnum } from '../../../core/TagUtil';
import { TagView } from '../../../core/TagView';
import { COL_SPANS } from './AnswersList';
import { answerTagsReducer, AnswerTagStateUpdate } from './AnswerTagState';

const TagColumn = memo(function TagColumn ({ answer, dispatch }) {
  const { tag } = answer

  const saveTag = useCallback((newTag) => {
    dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { tag: newTag } })
  }, [dispatch, answer])

  const removeTag = useCallback(() => {
    dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { tag: null } })
  }, [dispatch, answer])

  const handleCheckboxChange = useCallback((event) => {
    if (event.currentTarget.checked) {
      dispatch({ type: QuestionStateUpdate.SaveNewAnswerTag, answerId: answer.id, questionId: answer.questionId })
    }
  }, [dispatch, answer.id, answer.questionId])

  return (
    <Grid.Col span={COL_SPANS.tag}>
      <Center>
        {tag
          ? <AnswerTagPopover answer={answer} onSave={saveTag} onRemove={removeTag} />
          : <Checkbox onChange={handleCheckboxChange} />
        }
      </Center>
    </Grid.Col>
  )
})

export const AnswerTagPopover = ({ answer, onSave: handleSave, onRemove: handleRemove }) => {
  const { tag } = answer

  const [isOpen, setIsOpen] = useState(false)
  const [state, dispatch] = useReducer(answerTagsReducer, tag)

  const nameRef = useRef()
  const descriptionRef = useRef()

  const save = useCallback(() => {
    handleSave(state)
    setIsOpen(false)
  }, [handleSave, state])

  const clearName = useCallback(() => {
    dispatch({ type: AnswerTagStateUpdate.ClearField, payload: 'name' })
    nameRef.current.focus()
  }, [])

  const clearDescription = useCallback(() => {
    dispatch({ type: AnswerTagStateUpdate.ClearField, payload: 'description' })
    descriptionRef.current.focus()
  }, [])

  const displayIcon = tagIconFromEnum(state.icon, { size: '3rem' })
  const tagIcon = tagIconFromEnum(tag.icon, { size: '3rem' })

  const updateTag = useCallback((newAttributes) => {
    dispatch({ type: AnswerTagStateUpdate.SetTag, payload: newAttributes })
  }, [dispatch])

  const handleCheckboxChange = (event) => {
    updateTag({ clientVisible: !event.currentTarget.checked })
  }

  useEffect(() => {
    // reset the values to what's saved on redux, in case the user uses the undo button
    if (isOpen && tag) {
      dispatch({ type: AnswerTagStateUpdate.SetTag, payload: tag })
    }
  }, [isOpen, tag])

  return (
    <Popover position='top' opened={isOpen} onClose={() => setIsOpen(false)}>
      <Popover.Target>
        <Center h='100%'>
          <ActionIcon color={tag.color} onClick={() => setIsOpen((o) => !o)}>{tagIcon}</ActionIcon>
        </Center>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex justify='space-between' mb='xs'>
          <Text fw='bold' fz='lg' c='dimmed'>Modify Tag</Text>
          <ActionIcon onClick={() => setIsOpen(false)}>
            <IconX />
          </ActionIcon>
        </Flex>
        <Grid>
          <Grid.Col span={2}>
            <Center h='100%'>
              <Popover>
                <Popover.Target >
                  <Tooltip label='Change icon'>
                    <ActionIcon color={state.color} variant='light' size={60}>{displayIcon}</ActionIcon>
                  </Tooltip>
                </Popover.Target>
                <Popover.Dropdown>
                  <TagView
                    onTagClick={(newTag) => updateTag({ icon: newTag })}
                    onColorClick={(newColor) => updateTag({ color: newColor })}
                    currentColor={state.color}
                    currentTag={state.icon}
                  />
                </Popover.Dropdown>
              </Popover>
            </Center>
          </Grid.Col>
          <Grid.Col span={10}>
            <TextInput
              label='Name'
              value={state.name}
              ref={nameRef}
              onChange={(event) => updateTag({ name: event.currentTarget.value })}
              rightSection={<ActionIcon onClick={clearName}><IconX /></ActionIcon>}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label='Description'
              value={state.description}
              ref={descriptionRef}
              onChange={(event) => updateTag({ description: event.currentTarget.value })}
              rightSection={<ActionIcon onClick={clearDescription}><IconX /></ActionIcon>}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Flex justify='flex-end' gap='xs'>
              <Checkbox label='PM Only' description='Prevents clients from seeing tag' checked={!state.clientVisible} onChange={handleCheckboxChange} />
              <Tooltip label='Save tag'>
                <Button color='success' onClick={save}><IconCheck /></Button>
              </Tooltip>
              <Tooltip label='Remove tag'>
                <Button color='orange' onClick={handleRemove}><IconBan /></Button>
              </Tooltip>
            </Flex>
          </Grid.Col>
        </Grid>
      </Popover.Dropdown>
    </Popover>
  )
}

export { TagColumn }
