import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import StatusSetHome from './build/status_set/StatusSetHome'
import StatusSetEditor from './build/status_set/StatusSetEditor'
import Home from './hire/bvi/Home'
import { AssessmentHome } from './build/assessment/AssessmentHome'
import ImporterApp from './build/assessment/ImporterApp'
import AssessmentLoader from './build/assessment/AssessmentLoader'
import AssessmentEditor from './build/assessment/AssessmentEditor'
import EmailTemplateHome from './build/email_template/EmailTemplateHome'
import EmailTemplateEditor from './build/email_template/EmailTemplateEditor'
import AnalyticsTable from './analytics/AnalyticsTable'
import AddCandidate from './skillbuilder/AddCandidate'
import ContactApplicantApp from './hire/contact/ContactApplicantApp'
import ContactCycleApp from './hire/contact/ContactCycleApp'
import ContactHiringViewApp from './hire/contact/ContactHiringViewApp'
import CreateCycleTriggerApp from './hire/triggers/create/CreateCycleTriggerApp'
import EditCycleTriggerApp from './hire/triggers/edit/EditCycleTriggerApp'
import HiringTemplateTriggerEdit from './hire/triggers/edit/HiringTemplateTriggerEdit'
import HiringTemplateTriggerCreate from './hire/triggers/create/HiringTemplateTriggerCreate'
import Base from './layout/Base'
import { Box, Container } from '@mantine/core'
import { DetailViewTableApp } from './hire/cycle/DetailViewTable'
import CycleBatteryManagement from './hire/cycle/CycleBatteryManagement'
import HiringTemplateBatteryManagement from './build/hiring_template/HiringTemplateBatteryManagement'
import SemanticSearch from './semantic/SemanticSearch';
import CycleTriggerManagement from './hire/cycle/CycleTriggerManagement'
import HiringTemplateTriggerManagement from './build/hiring_template/HiringTemplateTriggerManagement'
import HiringTemplateDetailParams from './build/hiring_template/HiringTemplateDetailParams'
import CycleManagementDetailParams from './hire/cycle/CycleManagementDetailParams'
import { AuthLandingPage } from './hire/cycle/invites/landing/AuthLandingPage';
import { CyclePassHome } from './hire/cycle/invites/landing/CyclePassHome';
import { CyclePassThankYou } from './hire/cycle/invites/CyclePassThankYou';
import { CyclePassNotFound } from './hire/cycle/invites/CyclePassNotFound';
import LoginPage from './account/LoginPage'
import APLoginPage from './account/APLoginPage'
import APLoginOnSite from './account/APLoginOnSite'
import BoostInfo from './hire/cycle/Boost/BoostInfo';
import BoostDetail from './hire/cycle/Boost/BoostDetail';
import BoostCycle from './hire/cycle/Boost/BoostCycle';
import UserHome from './account/user/UserHome'
import ClientDetails from './account/user/ClientDetails'
import EditUser from './account/user/EditUser'
import { UserExport } from './account/user/UserExport'
import SkillbuilderCyclesHome from './skillbuilder/cycles/SkillbuilderCyclesHome'
import OrganizationCreate from './account/organizations/OrganizationCreate'
import OrganizationHome from './account/organizations/OrganizationHome'
import OrganizationEdit from './account/organizations/OrganizationEdit'
import OrganizationDelete from './account/organizations/OrganizationDelete'

export default function App () {
  return (
    <Base>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/cycles/:cycleId/beta-detail" element={<DetailViewTableApp/>}/>
          <Route path="/cycles" element={<LargeMarginOutlet/>}>
            <Route path=":cycleId">
              <Route path='invite' element={<><BoostDetail/><Outlet/></>}>
                <Route index element={<BoostCycle />}/>
                <Route path='info' element={<BoostInfo/>}/>
              </Route>
              <Route path="contact">
                <Route path="" element={<ContactCycleApp/>}/>
                <Route path=":applicantId" element={<ContactApplicantApp/>}/>
              </Route>
            </Route>
          </Route>
          <Route path='/your/invites' element={<LargeMarginOutlet />}>
            <Route path='thank-you' element={<CyclePassThankYou />} />
            <Route path='not-found' element={<CyclePassNotFound />} />
            <Route path='open/:invitePrefix/:inviteLink' element={<AuthLandingPage />} />
            <Route path=':invitePrefix/:inviteLink' element={<AuthLandingPage />} />
            <Route path=':cyclePassId' element={<CyclePassHome />} >
            </Route>
          </Route>
          <Route path="/" element={<PaddedContainerOutlet/>}>
            <Route path="build">
              <Route path="assessments">
                <Route path="" element={<AssessmentHome/>}/>
                <Route path="import" element={<ImporterApp/>}/>
                <Route path=":id/edit" element={<AssessmentLoader/>}/>
                <Route path="create" element={<AssessmentEditor/>}/>
              </Route>
              <Route path="email/templates">
                <Route path="" element={<EmailTemplateHome/>}/>
                <Route path="create" element={<EmailTemplateEditor/>}/>
                <Route path=":id/edit" element={<EmailTemplateEditor/>}/>
              </Route>
              <Route path="status-sets">
                <Route path="" element={<StatusSetHome/>}/>
                <Route path="create" element={<StatusSetEditor/>}/>
                <Route path=":id/edit" element={<StatusSetEditor/>}/>
              </Route>
              <Route path='hiring-templates'>
                <Route path=':id'>
                  <Route path='batteries' element={<><HiringTemplateDetailParams selected='batteries' /><Outlet /></>}>
                    <Route path='' element={<HiringTemplateBatteryManagement />} />
                  </Route>
                  <Route path='triggers' element={<><HiringTemplateDetailParams selected='triggers' /><Outlet /></>}>
                      <Route path='' element={<HiringTemplateTriggerManagement/>}/>
                      <Route path='create' element={<HiringTemplateTriggerCreate/>}/>
                      <Route path=':triggerId'>
                        <Route path='edit' element={<HiringTemplateTriggerEdit/>}/>
                      </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="hire">
              <Route path="setup/bvi" element={<Home/>}/>
              <Route path="report-results">
                <Route path="hiring-views">
                  <Route path=":id">
                    <Route path="contact" element={<ContactHiringViewApp/>}/>
                  </Route>
                </Route>
              </Route>
              <Route path='cycles'>
                <Route path=':id'>
                  <Route path='batteries' element={<><CycleManagementDetailParams selected='batteries' /><Outlet /></>}>
                    <Route path='' element={<CycleBatteryManagement/>}/>
                  </Route>
                  <Route path='triggers' element={<><CycleManagementDetailParams selected='triggers' /><Outlet /></>}>
                    <Route path='' element={<CycleTriggerManagement/>}/>
                    <Route path='create' element={<CreateCycleTriggerApp/>}/>
                    <Route path=':triggerId'>
                      <Route path='edit' element={<EditCycleTriggerApp/>}/>
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path="applicant/semantic">
                <Route path="search" element={<SemanticSearch />}/>
              </Route>
            </Route>
            <Route path="skillbuilder">
              <Route path="cycles">
                <Route index element={<SkillbuilderCyclesHome />} />
              </Route>
              <Route path="candidates">
                <Route path="add" element={<AddCandidate/>}/>
              </Route>
            </Route>
            <Route path="clients">
              <Route path="organizations">
                <Route index element={<OrganizationHome />} />
                <Route path="create" element={<OrganizationCreate />} />
                <Route path=":id/edit" element={<OrganizationEdit />} />
                <Route path=":id/delete" element={<OrganizationDelete />} />
              </Route>
              <Route path="users">
                <Route index element={<UserHome />} />
                <Route path="create" element={<ClientDetails />} />
                <Route path=":id/edit" element={<EditUser />} />
                <Route path="export" element={<UserExport />} />
              </Route>
            </Route>
            <Route path="analytics">
              <Route path="statistics" element={<AnalyticsTable/>}/>
            </Route>
          </Route>
          <Route path='/assessment-platform'>
            <Route path='login' element={<APLoginPage />} />
            <Route path='on-site/login' element={<APLoginOnSite />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Base>
  )
}

function PaddedContainerOutlet () {
  return (
    <Container fluid px="xs">
      <Outlet/>
    </Container>
  )
}

function LargeMarginOutlet () {
  return (
    <Box mx="2rem">
      <Outlet/>
    </Box>
  )
}
