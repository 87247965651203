/* eslint react/prop-types: 0 */
import React, { memo, useCallback } from 'react';
import { Alert, Box, Button, Group, Modal, rem, Stack, Text, Transition } from '@mantine/core';
import { useSelector } from 'react-redux';
import {
  selectAnswerableSkippedQuestionIdsAlreadyDisplayedToUserCount,
  selectHasAnyUnansweredRequiredQuestionIds,
  selectUnansweredRequiredQuestionIds
} from './questionSlice';
import { IconAlertCircle, IconSend } from '@tabler/icons-react';
import { selectSubmissionInProgress } from './pagesSlice';
import { SubmissionResponse } from './AssessmentResponseHandler';

export const SubmitAssessmentButton = memo(function SubmitAssessmentButton ({ submit, showSubmit, autofocus = false }) {
  const submitting = useSelector(state => selectSubmissionInProgress(state))
  console.debug('SubmitAssessmentButton updating', submit, showSubmit, submitting, autofocus)
  return (
    <Button
      rightSection={<IconSend style={{ width: rem(24), height: rem(24) }} strokeWidth={2} />}
      disabled={!showSubmit}
      loading={submitting}
      onClick={submit}
      size='xl'
      data-autofocus={autofocus}
      color='success'
    >
      Submit
    </Button>
  )
})

export const ConfirmSubmitMissingDataModal = memo(function ConfirmSubmitMissingDataModal ({ opened, close, children }) {
  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      overlayProps={{ blur: 3, backgroundOpacity: 0.7 }}
      withinPortal
      zIndex={700}
    >
      {children}
    </Modal>
  )
})

export const ConfirmSubmitMissingDataContent = memo(function ConfirmSubmitMissingDataContent ({ allowSubmit, submit }) {
  const remainingSkippedCount = useSelector(state => selectAnswerableSkippedQuestionIdsAlreadyDisplayedToUserCount(state))
  const oneSkipped = remainingSkippedCount === 1
  const missingRequiredQuestions = useSelector(state => selectUnansweredRequiredQuestionIds(state))
  const disableSubmit = !!missingRequiredQuestions.length
  const oneRequiredMissing = missingRequiredQuestions.length === 1
  const textContent = disableSubmit ? `${oneRequiredMissing ? 'One' : missingRequiredQuestions.length} required question${oneRequiredMissing ? ' is' : 's are'} marked 'Skip for now'` : `${oneSkipped ? 'One' : remainingSkippedCount} question${oneSkipped ? ' is' : 's are'} marked 'Skip for now'`
  return (
    <Box w='100%'>
      <Stack>
        <Alert variant='light' color='red' title={disableSubmit ? 'Unable to submit due to unanswered required questions' : 'Are you sure you want to submit?'} icon={<IconAlertCircle />} fz='xl'>
          <Text fz='lg' >{textContent}</Text>
        </Alert>
        <SubmitAssessmentButton showSubmit={allowSubmit && !disableSubmit} submit={submit} autofocus={true} />
      </Stack>
    </Box>
  )
})

export const AssessmentSubmit = memo(function AssessmentSubmit (
  {
    hasAnyUnansweredNotSkipped,
    hasAnyUnanswered,
    confirmSubmitAssessmentIfMissing,
    showSubmitConfirmation,
    setShowSubmitConfirmation,
    submitAssessment,
    openReview
  }
) {
  const closeCallback = useCallback(() => {
    setShowSubmitConfirmation(false)
  }, [setShowSubmitConfirmation])

  return (
    <Box w='100%'>
      <AssessmentFooterButtons
        hasAnyUnansweredNotSkipped={hasAnyUnansweredNotSkipped}
        hasAnyUnanswered={hasAnyUnanswered}
        confirmSubmitAssessmentIfMissing={confirmSubmitAssessmentIfMissing}
        openReview={openReview}
      />
      <ConfirmSubmitMissingDataModal opened={showSubmitConfirmation} close={closeCallback}>
        <ConfirmSubmitMissingDataContent allowSubmit={!hasAnyUnansweredNotSkipped} submit={submitAssessment} />
      </ConfirmSubmitMissingDataModal>
      <SubmissionResponse submit={submitAssessment} />
    </Box>
  )
})

const AssessmentFooterButtons = memo(function AssessmentFooterButtons ({ hasAnyUnansweredNotSkipped, hasAnyUnanswered, openReview, confirmSubmitAssessmentIfMissing }) {
  const disableSubmit = useSelector(state => selectHasAnyUnansweredRequiredQuestionIds(state))
  return (
    <Transition
      mounted={!hasAnyUnansweredNotSkipped}
      transition='fade'
      duration={2000}
      timingFunction='ease'
      keepMounted
    >
      {(transitionStyles) => (
        <div style={transitionStyles}>
          <Group justify='center'>
            <Button onClick={openReview} size='xl'>
              {hasAnyUnanswered ? 'Review Skipped' : 'Review'}
            </Button>
            <SubmitAssessmentButton submit={confirmSubmitAssessmentIfMissing} showSubmit={!hasAnyUnansweredNotSkipped && !disableSubmit} />
          </Group>
        </div>
      )}
    </Transition>
  )
})
