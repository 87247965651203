import React from 'react'
import { Button, Group, MultiSelect, Select, Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useGetAccountsQuery, useGetLoggedInAccountQuery } from '../../../redux/query/account/accountsApi.slice'
import Loading from '../../core/Loading'
import ErrorLoading from '../../core/ErrorLoading'
import { isGranted } from '../../../util/account/account.util'
import { useGetIntegrationsQuery } from '../../../redux/query/integration/integrationApi.slice'
import { Link } from 'react-router-dom'
import { IconBuildings, IconHeartHandshake, IconMail, IconSignature, IconUser } from '@tabler/icons-react'

/**
 * This component allows to manipulate the organization fields.
 * @param {object} initialValues the initial values for the form.
 * @param {func} onSubmit Function to execute when the form is submitted.
 * @param {boolean} isProcessing Whether the request is being processed and the submit button should be disabled.
 * @returns {JSX.Element}
 */
export default function OrganizationForm ({ initialValues, onSubmit, isProcessing }) {
  const { data: account, isError: accountError, isLoading: accountLoading } = useGetLoggedInAccountQuery()
  const { data: managers, isError: managersError, isLoading: managersLoading } = useGetAccountsQuery({ limit: 0, roles: ['ROLE_DEVELOPER', 'ROLE_ADMIN'] })
  const { data: integrations, isError: integrationsError, isLoading: integrationsLoading } = useGetIntegrationsQuery({ limit: 0 })

  const form = useForm({
    mode: 'controlled',
    initialValues: initialValues,
    validate: {
      name: value => value === '' ? 'Name is required' : null,
      abbreviation: value => {
        if (value !== '') {
          if (value.length < 2 || value.length > 5) {
            return 'Abbreviation should be between 2 and 5 characters long'
          }
        }

        return null
      }
    },
    transformValues: values => {
      const transformedValues = { ...values }

      if (transformedValues.abbreviation === '') {
        transformedValues.abbreviation = null
      }

      if (!isGranted(account, 'ROLE_DEVELOPER') || transformedValues.from_address === '') {
        transformedValues.from_address = null
      }

      if (transformedValues.manager === '0') {
        delete transformedValues.manager
      }

      return transformedValues
    }
  })

  if (accountError || managersError || integrationsError) {
    return <ErrorLoading />
  }

  if (accountLoading || managersLoading || integrationsLoading) {
    return <Loading />
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack w={{ sm: '100%', md: '75%', lg: '50%' }}>
        <TextInput
          label='Organization Name'
          placeholder='ABC Company'
          leftSection={<IconBuildings size='1.5rem' />}
          required
          {...form.getInputProps('name')}
        />
        <TextInput
          label='Abbreviation (2-5 characters long)'
          placeholder='ABC'
          leftSection={<IconSignature size='1.5rem' />}
          {...form.getInputProps('abbreviation')}
        />
        {
          isGranted(account, 'ROLE_DEVELOPER') &&
          <TextInput
            label='From Address (Optional)'
            placeholder='no-reply@hirescore.com'
            leftSection={<IconMail size='1.5rem' />}
            {...form.getInputProps('from_address')}
          />
        }
        <Select
          label='Project Manager'
          placeholder='None'
          leftSection={<IconUser size='1.5rem' />}
          searchable
          clearable
          data={managers.items.map(manager => ({
            value: manager.id.toString(),
            label: `${manager.first_name} ${manager.last_name} - <${manager.email}>`
          }))}
          {...form.getInputProps('manager')}
        />
        <MultiSelect
          label='Integrations'
          leftSection={<IconHeartHandshake size='1.5rem' />}
          placeholder={form.values.integrations.length === 0 ? 'None' : null}
          searchable
          clearable
          data={integrations.items.map(integration => ({
            value: integration.id.toString(),
            label: integration.name
          }))}
          {...form.getInputProps('integrations')}
        />
        <Group>
          <Button type='submit' color='teal.6' loading={isProcessing}>Save</Button>
          <Button component={Link} to='../' color='gray.6'>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}
