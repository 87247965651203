import React, { memo, useCallback, useRef, useState } from 'react';
import { animated, to, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import { Box, Paper, Stack, Title, Tooltip } from '@mantine/core';
import * as styles from '../../layout/animations/styles.module.css';

export const DEFAULT_BULK_ACTIONS_AFFIX_TOP = 50

export const DEFAULT_BULK_ACTIONS_AFFIX_LEFT = 20

const springConfigParams = {
  scale: 1,
  zoom: 0,
  x: 0,
  y: 0,
  config: { mass: 5, tension: 350, friction: 60 }
}

const BulkActionsController = memo(function BulkActionsController (
  {
    affixedTop = DEFAULT_BULK_ACTIONS_AFFIX_TOP,
    affixedLeft = DEFAULT_BULK_ACTIONS_AFFIX_LEFT,
    setDragging,
    children
  }
) {
  const [hovered, setHovered] = useState(false)
  const [everDragged, setEverDragged] = useState(false)
  const domTarget = useRef(null)
  const ref = useRef(null)
  const springConfig = useCallback(() => {
    return springConfigParams
  }, [])

  const [{ x, y, zoom, scale }, api] = useSpring(
    springConfig
  )

  /*
  * Fallback to inner height/width if the visualViewport is not available. This is mainly done to support testing as the
  * visualViewport is not available within the jest tests. While modifying code specifically for testing isn't the best
  * it's possible albeit unlikely for a users browser to not support it either.
  */
  const viewportHeight = window?.visualViewport?.height ?? window.innerHeight
  const viewportWidth = window?.visualViewport?.width ?? window.innerWidth
  const affixedTopDistance = viewportHeight * (affixedTop / 100)
  const affixedLeftDistance = viewportWidth * (affixedLeft / 100)

  useGesture(
    {
      onDrag: (state) => {
        const { active, canceled, offset: [x, y] } = state
        console.debug('Called BulkActionsControl on drag', { active, x, y, canceled, state })
        api({ x, y })
        setDragging(active)

        if (active) {
          setEverDragged(true)
        }
      },
      onMove: ({ dragging }) => {
        if (dragging !== undefined) {
          if (!dragging) {
            api({
              scale: 1.1
            })
          }
        }
      },
      onHover: ({ hovering }) => {
        if (hovering !== undefined) {
          if (!hovering) {
            api({ scale: 1 })
          }
          setHovered(hovering)
        }
      }
    },
    {
      target: domTarget,
      drag: {
        threshold: 10,
        /*
        * The Actions are affixed by a percentage of the viewport from the top and left. By calculating the exact distance
        * this is we can determine how far the actions can move in those directions before being dragged off the screen.
        * To prevent the actions from being dragged off the screen from the right or bottom, the previously calculated
        * values and the components height or width are subtracted from the viewPorts height or width.
        */
        bounds: {
          left: -affixedLeftDistance,
          right: viewportWidth - affixedLeftDistance - (ref.current?.clientWidth ?? 0),
          top: -affixedTopDistance,
          bottom: viewportHeight - affixedTopDistance - (ref.current?.clientHeight ?? 0)
        }
      },
      eventOptions: { passive: false }
    }
  )

  return (
    <Box pos='relative' ref={ref} aria-label='Bulk Actions'>
      <Tooltip label='Drag to Move' opened={hovered && !everDragged} disabled={everDragged}>
        <animated.div
          ref={domTarget}
          className={styles.bulk}
          style={{
            transform: 'perspective(600px)',
            x: x,
            y: y,
            scale: to([scale, zoom], (s, z) => Math.max(s + z, -s + Math.abs(z), 0.5))
          }}
        >
          <Paper>
            <Stack maw='10rem' gap='xs' justify='flex-start'>
              <Title ta='center' order={4} style={{ userSelect: 'none' }}>Bulk Actions</Title>
              {children}
            </Stack>
          </Paper>
        </animated.div>
      </Tooltip>
    </Box>
  )
})

export default BulkActionsController
