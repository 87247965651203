import {
  calculateMaxDecimalsForScale,
  setStateEntryPosition,
  sortByPosition,
  sortStateEntriesWithInsertedPosition
} from './util';
import { sectionPositionFromId, SectionPosition } from '../../../../js/generated/enums/SectionPosition';
import { mediaTypeFromId } from '../../../../js/generated/enums/MediaType';
import { notifications } from '@mantine/notifications';
import { QuestionType } from '../../../../js/generated/enums/QuestionType';
import {
  canChangeAnswerOrientation,
  hasFixedAnswers,
  isNumbered,
  isScored
} from '../../../../js/modules/Build/Assessment/QuestionType';
import { stripContentTags } from './UnpublishedQuestionLogic/util';
import { TagIcon } from '../../../../js/generated/enums/TagIcon';
import { ResponseRequirement } from '../../../../js/generated/enums/ResponseRequirement';

/**
 * @typedef {object} AnswerTag
 * @property {string} name
 * @property {string} description
 * @property {string} color
 * @property {number} icon
 * @property {boolean} clientVisible
 */

/**
 * @typedef {object} Answer
 * @property {int} id
 * @property {int} questionId
 * @property {int} position
 * @property {int} correct
 * @property {string} content
 * @property {number} score
 * @property {?number} knockoutValue
 * @property {?AnswerTag} tag
 */

/**
 * @typedef {object} QuestionMedia
 * @property {int} id
 * @property {string} name
 * @property {string} description
 * @property {?int} height
 * @property {?int} width
 * @property {int} typeId
 * @property {string} type
 * @property {string} identifier
 * @property {string} link
 * @property {string} uploaded
 */

/**
 * @typedef {object} QuestionLogic
 * @property {int} id
 * @property {?int} parentId ?QuestionLogicId
 * @property {?int} parentQuestionId
 * @property {?int} questionId Only null when creating
 * @property {?string} operator Only null when creating
 * @property {string} value
 * @property {?string} feature Only null when creating
 * @property {QuestionLogic[]} children
 * @property {?int} nodeGroupId ?QuestionLogicId indicating original node (for visually grouping similar nodes when editing)
 */

/**
 * @typedef {object} QuestionCategory
 * @property {int} id
 * @property {QuestionMapping} type
 * @property {?QuestionSubMapping} subType
 * @property {?string} customFieldName
 * @property {int} unpublishedQuestionCount
 * @property {int} publishedQuestionCount
 */

/**
 * @typedef {object} Question
 * @property {int} id
 * @property {string} type
 * @property {int} position
 * @property {ResponseRequirement} required
 * @property {int} blockId
 * @property {string} section  // TODO backend replace with section.Name
 * @property {int} pageBreak
 * @property {?int} randomizedPool
 * @property {int} vertical
 * @property {int} top
 * @property {int} comment
 * @property {string} content
 * @property {?QuestionMedia} media
 * @property {int} mediaPlayOnce
 * @property {int} directLinkId
 * @property {int} variedLinkId
 * @property {?string} keywords
 * @property {int} isScored
 * @property {int} isDropdown
 * @property {int} valueRating
 * @property {Competency[]} competencies
 * @property {Map.<int: Answer>} answers <intId: Answer>
 * @property {boolean} isNumbered
 * @property {?int} number
 * @property {int|null|undefined} originalNumber?
 * @property {boolean} isScorable  isScored(questionType),
 * @property {boolean} answersOrientable canChangeAnswerOrientation(questionType),
 * @property {QuestionLogic[]} logic
 * @property {QuestionCategory[]} categories
 * @property {bool} hasLegacyMaxScore
 * @property {bool} hasLegacyMinScore
 */

/**
 * @typedef {object} SourceAnswerJson
 * @augments Answer
 * @property {string} score
 * @property {undefined} questionId
 * @property {?string} knockout
 */

/**
 * @typedef {object} SourceQuestionJson
 * @augments Question
 * @property {SourceAnswerJson[]} answers Array<SourceAnswerJson>
 * @property {?string} legacy_max_score
 * @property {?string} legacy_min_score
 */

/**
 * @typedef {object} QuestionsState
 * @property {Map.<int: Question>} questions <intId: Question>
 * @property {int} nextFakeId Always negative, iteration shared between questions and answers.
 * @property {?int} activeQuestionId
 * @property {?int} activeAnswerId
 * @property {Map.<int: int>} importedQuestionIdMap <realIntId: newFakeId>
 * @property {Map.<int: int>} importedAnswerIdMap <realIntId: newFakeId>
 * @property {Map.<int: Question>[]} questionsHistory
 * @property {int} activeQuestionsHistoryIndex
 * @property {int} maxQuestionsHistoryLength
 */

/**
 * @param {?(SourceQuestionJson[])} questionsJson
 * @returns {QuestionsState}
 */
function createInitialQuestionsState (questionsJson = null) {
  const blankState = {
    questions: new Map(),
    nextFakeId: -1,
    activeQuestionId: null,
    activeAnswerId: null,
    importedQuestionIdMap: new Map(),
    importedAnswerIdMap: new Map(),
    questionsHistory: [new Map()],
    activeQuestionsHistoryIndex: 0,
    maxQuestionsHistoryLength: 15
  }
  if (!questionsJson?.length) {
    console.info('Initial questions state loading skipped.', questionsJson, blankState)
    return blankState
  }
  const stateQuestions = formatQuestionsFromJson(questionsJson, true, true)
  console.info('Initial questions state loaded - inserting.', stateQuestions, blankState, questionsJson)
  return { ...blankState, questions: stateQuestions, questionsHistory: [new Map(stateQuestions)] }
}

/**
 * @param {SourceQuestionJson[]} questionsJson
 * @param {boolean} sortQuestions
 * @param {boolean} setOriginalPositions
 * @returns {Map.<int: Question>}
 */
function formatQuestionsFromJson (questionsJson, sortQuestions = true, setOriginalPositions = false) {
  const stateQuestions = new Map()
  const sortedQuestions = sortQuestions ? sortByPosition(questionsJson) : questionsJson
  let nonNumberedQuestions = 0
  let numberQuestions = 0
  for (const question of sortedQuestions) {
    const questionAnswers = new Map()
    for (const answer of sortByPosition(question.answers)) {
      const knockoutValue = (answer.knockout ?? null) || null
      questionAnswers.set(
        answer.id,
        {
          ...answer,
          questionId: question.id,
          score: Math.abs(parseInt(answer.score) - parseFloat(answer.score)) < 0.00001
            ? parseInt(answer.score)
            : parseFloat(answer.score),
          knockoutValue: knockoutValue
            ? (Math.abs(parseInt(knockoutValue) - parseFloat(knockoutValue)) < 0.00001
                ? parseInt(knockoutValue)
                : parseFloat(knockoutValue))
            : null,
          tag: answer.tag
            ? ({
                name: answer.tag.flag_template.default_title,
                description: answer.tag.flag_template.default_text,
                color: answer.tag.color,
                icon: answer.tag.icon,
                clientVisible: !!answer.tag.flag_template.type
              })
            : null
        })
    }
    const questionType = question.type
    const numbered = isNumbered(questionType)
    if (!numbered) {
      nonNumberedQuestions += 1
    }
    numberQuestions += 1
    const questionNumber = numberQuestions - nonNumberedQuestions
    const originalNumberDict = setOriginalPositions ? { originalNumber: questionNumber } : {}
    const formatLogic = (logic, fromParent) => {
      return {
        id: logic.id,
        questionId: logic.question_id ?? null,
        operator: logic.operator ?? null,
        value: logic.value ?? '',
        feature: logic.feature ?? null,
        children: logic.children.map((child) => formatLogic(child, { ...fromParent, parentId: logic.id })),
        parentId: null,
        parentQuestionId: null,
        nodeGroupId: logic.node_group_id ?? null,
        ...fromParent
      }
    }
    const formatCategory = (category) => {
      const formattedCategory = {
        subType: category.sub_type ?? null,
        customFieldName: category.custom_field_name ?? null,
        ...category
      }
      formattedCategory.value = (formattedCategory.customFieldName ? formattedCategory.subType + ' ' + formattedCategory.customFieldName : null) ?? formattedCategory.subType ?? formattedCategory.type
      formattedCategory.label = formattedCategory.value
      formattedCategory.group = formattedCategory.subType ? formattedCategory.type : 'General'
      formattedCategory.searchable = ((formattedCategory.customFieldName ? [formattedCategory.type, formattedCategory.subType, formattedCategory.customFieldName].join(' ') : null) ?? (formattedCategory.subType ? [formattedCategory.type, formattedCategory.subType].join(' ') : formattedCategory.type)).toLowerCase().trim()
      return formattedCategory
    }
    stateQuestions.set(
      question.id,
      {
        ...question,
        ...originalNumberDict,
        type: questionType,
        number: questionNumber,
        isNumbered: numbered,
        answersOrientable: canChangeAnswerOrientation(questionType),
        isScorable: isScored(questionType),
        answers: questionAnswers,
        isDropdown: question.is_dropdown,
        isScored: question.is_scored,
        section: sectionPositionFromId(question.section),
        randomizedPool: ((question.randomized_pool?.toString() && question.randomized_pool.toString() !== 'null') ? question.randomized_pool : null),
        pageBreak: question.page_break,
        directLinkId: question.direct_link_id ?? 0,
        variedLinkId: question.varied_link_id ?? 0,
        mediaPlayOnce: question.media_play_once,
        media: (question.media
          ? {
              ...question.media,
              typeId: question.media.type,
              type: mediaTypeFromId(question.media.type)
            }
          : null),
        logic: question.logic?.map((logic) => formatLogic(logic, { parentQuestionId: question.id })) ?? [],
        categories: question.categories?.map(category => formatCategory(category)) ?? [],
        hasLegacyMaxScore: !!question.legacy_max_score,
        hasLegacyMinScore: !!question.legacy_min_score,
        validationType: question.validation_type ?? null
      })
  }
  return stateQuestions
}

const QuestionStateUpdate = Object.freeze({
  NewQuestion: 'new-question',
  NewAnswer: 'new-answer',
  ReplaceAnswers: 'replace-answers',
  SortQuestions: 'sort-questions',
  SortAnswers: 'sort-answers',
  RemoveQuestion: 'remove-question',
  RemoveAnswer: 'remove-answer',
  FocusQuestion: 'focus-question',
  UpdateQuestionContent: 'update-question-content',
  UpdateQuestion: 'update-question', // Do *not* misuse this to update attributes controlled by other enum variants. (position, answers)
  RandomizeQuestion: 'randomize-question',
  FocusAnswer: 'focus-answer',
  UpdateAnswer: 'update-answer',
  SetCorrectAnswer: 'set-correct-answer',
  SetAnswerScore: 'set-answer-score',
  MediaPopupSelect: 'set-active-media',
  ImportQuestions: 'import-questions',
  UndoQuestionUpdate: 'undo-question-update',
  RedoQuestionUpdate: 'redo-question-update',
  SaveNewAnswerTag: 'save-new-answer-tag'
})

const RandomizedPoolEvent = Object.freeze({
  Increment: 'left-click',
  Decrement: 'right-click',
  Reset: 'middle-click',
  TogglePageBreak: 'make-page-break'
})

const MAX_RANDOM_POOL = 10

function makeRatingScaleValues (start, stepSize, stepCount) {
  const maxDecimals = calculateMaxDecimalsForScale(start, stepSize, stepCount)
  const roundValue = (value) => value.toLocaleString('fullwide', { maximumFractionDigits: maxDecimals })

  let currentValue = start
  let currentStep = 0
  const answers = []
  while (currentStep < stepCount) {
    answers.push({ score: currentValue, content: roundValue(currentValue) })
    currentValue += stepSize
    currentStep += 1
  }
  return answers
}

function makeFixedAnswerConfigs (questionType) {
  switch (questionType) {
    case QuestionType.YesNo:
      return [
        { position: 0, content: 'Yes', correct: 1, score: 1 },
        { position: 1, content: 'No', correct: 0, score: 0 }
      ]
    case QuestionType.TrueFalse:
      return [
        { position: 0, content: 'True', correct: 1, score: 1 },
        { position: 1, content: 'False', correct: 0, score: 0 }
      ]
    case QuestionType.FillInTheBlank:
      return [{ position: 0, content: 'New Answer', correct: 1, score: 1 }]
    case QuestionType.Interview:
      return makePresetRatingAnswerConfigs(['Impressive', '', 'Adequate', '', 'Poor'])
    case QuestionType.PoorAdequateImpressive:
      return makePresetRatingAnswerConfigs(['Poor', '', 'Adequate', '', 'Impressive'])
    case QuestionType.ExpertiseSelfRating:
      return makePresetRatingAnswerConfigs(['', '', '', '', ''])
    case QuestionType.PoorSatisfactoryExcellent:
      return makePresetRatingAnswerConfigs(['Poor', 'Fair', 'Satisfactory', 'Good', 'Excellent'])
    case QuestionType.Multiline:
    case QuestionType.ShortAnswer:
      return [{ position: 0, content: '', correct: 0, score: 0 }]
    default:
      return []
  }
}

function makePresetRatingAnswerConfigs (answers) {
  return answers.map((answer, index) => {
    return {
      correct: 0,
      position: index,
      content: answer,
      score: index + 1
    }
  })
}

/**
 * @param {*&QuestionsState} state
 * @param {object} action
 * @param {QuestionStateUpdate} action.type
 * @param {int|undefined} action.questionId? used in NewAnswer|SortQuestions|SortAnswers|RemoveQuestion|RemoveAnswer|FocusQuestion|UpdateQuestion|RandomizeQuestion
 * @param {int|undefined} action.answerId? used in SortAnswers|RemoveAnswer
 * @param {int|undefined} action.newPosition? used in SortQuestions|SortAnswers
 * @param {QuestionType|undefined} action.questionType? used in NewQuestion
 * @param {{ start: number, stepSize: number, stepCount: number }|undefined} action.ratingScaleConfig? used in NewQuestion
 * @param {string|undefined} action.newContent? used in UpdateQuestionContent
 * @param {object|undefined} action.newAttributes? used in UpdateQuestion
 * @param {string|undefined} action.poolEvent? used in RandomizeQuestion
 * @param {int|undefined} action.newScore? used in SetCorrectAnswer
 * @param {QuestionMedia|null|undefined} action.newMedia? used in MediaPopupSelect
 * @param {Map.<int: Question>} action.newQuestions? used in ImportQuestions
 * @param {boolean|undefined} action.preservePageBreaks? used in ImportQuestions
 * @returns {*&QuestionsState}
 */
function questionsReducer (state, action) {
  // TODO handle/allow changing state max history length via config mid-edit? store in cookie?
  switch (action.type) {
    case QuestionStateUpdate.FocusQuestion:
    case QuestionStateUpdate.FocusAnswer: {
      return baseQuestionsReducer(state, action)
    }
    case QuestionStateUpdate.UndoQuestionUpdate: {
      if (state.activeQuestionsHistoryIndex <= 0) {
        console.warn(
          'Cannot undo action - no remaining histories to restore.',
          state.questionsHistory,
          state.activeQuestionsHistoryIndex
        )
        return state
      }
      const newState = { ...state }
      newState.activeQuestionsHistoryIndex -= 1
      newState.questions = new Map(newState.questionsHistory[newState.activeQuestionsHistoryIndex])
      console.debug(
        'Updated state questions due to undo. New questions | new index | history | old questions:',
        newState.questions,
        newState.activeQuestionsHistoryIndex,
        newState.questionsHistory,
        state.questions
      )
      if (newState.activeQuestionId && !newState.questions.has(newState.activeQuestionId)) {
        console.debug(
          'State no longer has question referred to by active question id after undo - setting to null for better ux.',
          newState.activeQuestionId,
          newState.questions
        )
        newState.activeQuestionId = null
      }
      return newState
    }
    case QuestionStateUpdate.RedoQuestionUpdate: {
      if (state.activeQuestionsHistoryIndex >= (state.questionsHistory.length - 1)) {
        console.warn(
          'Cannot redo action - no remaining histories to restore.',
          state.questionsHistory,
          state.activeQuestionsHistoryIndex
        )
        return state
      }
      const newState = { ...state }
      newState.activeQuestionsHistoryIndex += 1
      newState.questions = new Map(newState.questionsHistory[newState.activeQuestionsHistoryIndex])
      console.debug(
        'Updated state questions due to redo. New questions | new index | history | old questions:',
        newState.questions,
        newState.activeQuestionsHistoryIndex,
        newState.questionsHistory,
        state.questions
      )
      if (newState.activeQuestionId && !newState.questions.has(newState.activeQuestionId)) {
        console.debug(
          'State no longer has question referred to by active question id after redo - setting to null for better ux.',
          newState.activeQuestionId,
          newState.questions
        )
        newState.activeQuestionId = null
      }
      return newState
    }
    default: {
      const newState = baseQuestionsReducer(state, action)
      if (!Object.is(state.questions, newState.questions)) {
        console.debug(
          'State questions changed from action type - adding to state history.',
          action.type
        )
        if (newState.activeQuestionsHistoryIndex === (newState.questionsHistory.length - 1)) {
          newState.questionsHistory = [...newState.questionsHistory, new Map(newState.questions)]
          if (newState.questionsHistory.length <= newState.maxQuestionsHistoryLength) {
            newState.activeQuestionsHistoryIndex += 1
          } else {
            newState.questionsHistory.shift();
          }
          console.debug(
            'Appended new questions state to history. New history | new index:',
            newState.questionsHistory,
            newState.activeQuestionsHistoryIndex
          )
        } else {
          newState.questionsHistory = [
            ...newState.questionsHistory.slice(0, newState.activeQuestionsHistoryIndex + 1),
            new Map(newState.questions)
          ]
          newState.activeQuestionsHistoryIndex += 1
          console.debug(
            'Sliced state history update due to previous undo/redo. New history | new index | old history | old index:',
            newState.questionsHistory,
            newState.activeQuestionsHistoryIndex,
            state.questionsHistory,
            state.activeQuestionsHistoryIndex
          )
        }
      } else {
        console.debug(
          'State questions did not change from base questions reducer for action type - not adding history.',
          action.type
        )
      }
      return newState
    }
  }
}

/**
 * @param {*&QuestionsState} state
 * @param {object} action
 * @param {QuestionStateUpdate} action.type
 * @param {int|undefined} action.questionId? used in NewAnswer|SortQuestions|SortAnswers|RemoveQuestion|RemoveAnswer|FocusQuestion|UpdateQuestion|RandomizeQuestion
 * @param {int|undefined} action.answerId? used in SortAnswers|RemoveAnswer
 * @param {int|undefined} action.newPosition? used in SortQuestions|SortAnswers
 * @param {QuestionType|undefined} action.questionType? used in NewQuestion
 * @param {{ start: number, stepSize: number, stepCount: number }|undefined} action.ratingScaleConfig? used in NewQuestion
 * @param {string|undefined} action.newContent? used in UpdateQuestionContent
 * @param {object|undefined} action.newAttributes? used in UpdateQuestion
 * @param {string|undefined} action.poolEvent? used in RandomizeQuestion
 * @param {int|undefined} action.newScore? used in SetCorrectAnswer
 * @param {QuestionMedia|null|undefined} action.newMedia? used in MediaPopupSelect
 * @param {Map.<int: Question>} action.newQuestions? used in ImportQuestions
 * @param {boolean|undefined} action.preservePageBreaks? used in ImportQuestions
 * @returns {*&QuestionsState}
 */
function baseQuestionsReducer (state, action) {
  switch (action.type) {
    case QuestionStateUpdate.NewQuestion: {
      const lastQuestion = state.questions.size ? Array.from(state.questions.values())[state.questions.size - 1] : null
      const countPreviousNotNumbered = lastQuestion ? (lastQuestion.position + 1) - lastQuestion.number : 0
      const newNumber = (state.questions.size + 1) - countPreviousNotNumbered
      const canBeScored = isScored(action.questionType)
      const newQuestion = {
        id: state.nextFakeId,
        answers: new Map(),
        content: action.questionType === QuestionType.SectionHeader ? '<h4><span style="color: #636363">New Header</span></h4>' : '<p><span style="color: #636363">New Question</span></p>',
        section: SectionPosition.Center,
        isScored: canBeScored ? 1 : 0,
        isScorable: canBeScored,
        answersOrientable: canChangeAnswerOrientation(action.questionType),
        isDropdown: 0,
        isNumbered: isNumbered(action.questionType),
        number: newNumber,
        pageBreak: 0,
        position: state.questions.size,
        type: action.questionType,
        media: null,
        top: 2,
        directLinkId: 0,
        variedLinkId: 0,
        mediaPlayOnce: 0,
        comment: 0,
        required: ResponseRequirement.SubmitWithAlert,
        vertical: action.questionType === QuestionType.RatingScale ? 0 : 1,
        competencies: [],
        keywords: '',
        logic: [],
        randomizedPool: null,
        categories: [],
        validationType: null
      }
      const newQuestions = new Map(state.questions)
      newQuestions.set(newQuestion.id, newQuestion)
      const newState = {
        ...state,
        activeQuestionId: newQuestion.id,
        activeAnswerId: null,
        questions: newQuestions,
        nextFakeId: newQuestion.id - 1
      }
      if (action.ratingScaleConfig) {
        const { start, stepSize, stepCount } = action.ratingScaleConfig
        const answerConfigs = makeRatingScaleValues(start, stepSize, stepCount)
        let nextFakeId = newState.nextFakeId
        for (const { content, score } of answerConfigs) {
          const newAnswer = {
            id: nextFakeId,
            questionId: newQuestion.id,
            correct: 0,
            content: content,
            score: score,
            position: newQuestion.answers.size,
            knockoutValue: null,
            tag: null
          }
          newQuestion.answers.set(newAnswer.id, newAnswer)
          nextFakeId -= 1
        }
        newState.nextFakeId = nextFakeId
        if (stepCount > 11) {
          newQuestion.isDropdown = 1
        }
      } else if (hasFixedAnswers(newQuestion.type)) {
        const answerConfigs = makeFixedAnswerConfigs(newQuestion.type)
        let nextFakeId = newState.nextFakeId
        for (const answerConfig of answerConfigs) {
          const newAnswer = {
            id: nextFakeId,
            questionId: newQuestion.id,
            knockoutValue: null,
            tag: null,
            ...answerConfig
          }
          newQuestion.answers.set(newAnswer.id, newAnswer)
          nextFakeId -= 1
        }
        newState.nextFakeId = nextFakeId
      }
      return newState
    }
    case QuestionStateUpdate.NewAnswer: {
      const question = state.questions.get(action.questionId)
      let score = 0
      if (question.type === QuestionType.FillInTheBlank) {
        score = 1
      } else if (question.type === QuestionType.RatingScale) {
        const existingAnswers = Array.from(question.answers.values())
        if (existingAnswers.length) {
          const lastAnswer = existingAnswers[existingAnswers.length - 1]
          if (existingAnswers.length > 1) {
            const secondLastAnswer = existingAnswers[existingAnswers.length - 2]
            score = lastAnswer.score + (lastAnswer.score - secondLastAnswer.score)
          } else {
            score = lastAnswer.score * 2
          }
        } else {
          score = 1
        }
      }
      const newAnswer = {
        id: state.nextFakeId,
        questionId: question.id,
        correct: question.type === QuestionType.FillInTheBlank ? 1 : 0,
        content: 'New Answer',
        score: score,
        position: question.answers.size,
        knockoutValue: null,
        tag: null
      }
      const newAnswers = new Map(question.answers)
      newAnswers.set(newAnswer.id, newAnswer)
      const newState = {
        ...state,
        questions: new Map(state.questions),
        activeAnswerId: newAnswer.id,
        nextFakeId: newAnswer.id - 1
      }
      newState.questions.set(question.id, {
        ...question,
        answers: newAnswers
      })
      return newState
    }

    case QuestionStateUpdate.ReplaceAnswers: {
      const question = state.questions.get(action.questionId)
      if (!question) {
        console.error('Target question not in state for replace answers.', state, action)
        return state
      }
      const newAnswers = new Map()
      let nextFakeId = state.nextFakeId
      action.payload.forEach((answer, index) => {
        const newAnswer = {
          id: nextFakeId,
          questionId: question.id,
          correct: answer.correct,
          content: answer.content,
          score: answer.score,
          position: index,
          knockoutValue: answer.knockoutValue ?? null,
          tag: answer.tag ?? null
        }
        newAnswers.set(newAnswer.id, newAnswer)
        nextFakeId--
      })

      const newState = {
        ...state,
        nextFakeId: nextFakeId,
        questions: new Map(state.questions)
      }

      newState.questions.set(question.id, {
        ...question,
        answers: newAnswers
      })

      return newState
    }
    case QuestionStateUpdate.SortQuestions: {
      const newQuestions = setStateEntryPosition(
        state.questions,
        state.questions.get(action.questionId),
        action.newPosition
      )

      return Object.is(newQuestions, state.questions) ? state : { ...state, questions: newQuestions }
    }
    case QuestionStateUpdate.SortAnswers: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      const newAnswers = sortStateEntriesWithInsertedPosition(
        targetQuestion.answers,
        targetQuestion.answers.get(action.answerId),
        action.newPosition
      )

      if (Object.is(newAnswers, targetQuestion.answers)) {
        return state
      }
      return setStateQuestionAnswers(state, targetQuestion, newAnswers)
    }
    case QuestionStateUpdate.RemoveQuestion: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      if (!targetQuestion) {
        console.error('Target question not in state for remove question.', state, action)
        return state
      }
      const lastQuestionPosition = state.questions.size - 1
      const questionIsLast = (targetQuestion.position === lastQuestionPosition)
      let newQuestions = (questionIsLast)
        ? state.questions
        : setStateEntryPosition(
          state.questions,
          targetQuestion,
          lastQuestionPosition
        )
      if (Object.is(newQuestions, state.questions)) {
        newQuestions = new Map(state.questions)
      }
      newQuestions.delete(targetQuestionId)
      return {
        ...state,
        questions: newQuestions,
        activeQuestionId: (state.activeQuestionId === targetQuestionId) ? null : state.activeQuestionId
      }
    }
    case QuestionStateUpdate.RemoveAnswer: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      if (!targetQuestion) {
        console.error('Target question not in state for remove answer.', state, action)
        return state
      }
      const targetAnswerId = action.answerId
      const targetAnswer = targetQuestion.answers.get(targetAnswerId)
      if (!targetAnswer) {
        console.error(
          'Target answer not in state for remove answer.',
          targetAnswerId,
          targetQuestion.answers,
          state,
          action
        )
        return state
      }
      const lastAnswerPosition = targetQuestion.answers.size - 1
      const answerIsLast = (targetAnswer.position === lastAnswerPosition)
      let newAnswers = answerIsLast
        ? targetQuestion.answers
        : sortStateEntriesWithInsertedPosition(
          targetQuestion.answers,
          targetAnswer,
          lastAnswerPosition
        )
      if (Object.is(newAnswers, targetQuestion.answers)) {
        newAnswers = new Map(targetQuestion.answers)
      }
      newAnswers.delete(targetAnswerId)
      return setStateQuestionAnswers(state, targetQuestion, newAnswers)
    }
    case QuestionStateUpdate.FocusQuestion: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      if (targetQuestion) {
        if (state.activeQuestionId === targetQuestionId) {
          return {
            ...state,
            activeQuestionId: null
          }
        }
        const activeAnswerId = (state.activeAnswerId && targetQuestion.answers.has(state.activeAnswerId))
          ? state.activeAnswerId
          : null
        return {
          ...state,
          activeAnswerId: activeAnswerId,
          activeQuestionId: targetQuestionId
        }
      }
      return state
    }
    case QuestionStateUpdate.UpdateQuestionContent: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      console.debug(
        'Got update question content request. RequestId | RequestContent | StateActiveId | StateContent',
        action.questionId,
        action.newContent,
        state.activeQuestionId,
        targetQuestion?.content
      )
      const newContent = action.newContent
      if ((!targetQuestion) || (targetQuestion.content === newContent)) {
        if (!targetQuestion) {
          console.error('No target question found when doing question content update.', targetQuestionId, state, action)
        }
        return state
      }
      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      newState.questions.set(targetQuestionId, {
        ...targetQuestion,
        content: newContent
      })
      return newState
    }
    case QuestionStateUpdate.UpdateQuestion: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      const newAttributes = action.newAttributes
      if (!targetQuestion) {
        console.error('No target question found when doing question update.', targetQuestionId, state, action)
        return state
      }
      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      newState.questions.set(targetQuestionId, {
        ...targetQuestion,
        ...newAttributes
      })
      return newState
    }
    case QuestionStateUpdate.RandomizeQuestion: {
      console.debug('Got randomize question state update request.', action, state)
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      const newQuestion = {
        ...targetQuestion
      }
      newState.questions.set(targetQuestionId, newQuestion)
      const currentRandomPool = newQuestion.randomizedPool
      switch (action.poolEvent) {
        case RandomizedPoolEvent.Increment: {
          if (!currentRandomPool) {
            newQuestion.randomizedPool = 1
          } else if (currentRandomPool < MAX_RANDOM_POOL) {
            newQuestion.randomizedPool = currentRandomPool + 1
          } else {
            newQuestion.randomizedPool = null
          }
          return newState
        }
        case RandomizedPoolEvent.Decrement: {
          if (!currentRandomPool) {
            newQuestion.randomizedPool = MAX_RANDOM_POOL
          } else if (currentRandomPool > 1) {
            newQuestion.randomizedPool = currentRandomPool - 1
          } else {
            newQuestion.randomizedPool = null
          }
          return newState
        }
        case RandomizedPoolEvent.Reset: {
          if (!currentRandomPool) {
            return state
          }
          newQuestion.randomizedPool = null
          return newState
        }
        case RandomizedPoolEvent.TogglePageBreak: {
          newQuestion.pageBreak = newQuestion.pageBreak ? 0 : 1
          return newState
        }
        default: {
          console.error('Unknown randomize question event - skipping state update.', action, state)
          return state
        }
      }
    }
    case QuestionStateUpdate.FocusAnswer: {
      const targetAnswerId = action.answerId
      if (state.activeAnswerId !== targetAnswerId) {
        return {
          ...state,
          activeAnswerId: targetAnswerId
        }
      }
      return state
    }
    case QuestionStateUpdate.UpdateAnswer: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      const newAttributes = action.newAttributes
      if (!targetQuestion) {
        console.error('No target question found when doing answer update.', targetQuestionId, state, action)
        return state
      }
      const targetAnswerId = action.answerId
      const targetAnswer = targetQuestion.answers.get(targetAnswerId)
      if (!targetAnswer) {
        console.error('No target answer found when doing answer update.', targetAnswerId, state, action)
        return state
      }
      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      const newQuestion = { ...targetQuestion, answers: new Map(targetQuestion.answers) }
      newQuestion.answers.set(targetAnswerId, { ...targetAnswer, ...newAttributes })
      newState.questions.set(targetQuestionId, newQuestion)

      return newState
    }
    case QuestionStateUpdate.SetCorrectAnswer: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      if (!targetQuestion) {
        console.error('No target question found when doing select correct answer update.', targetQuestionId, state, action)
        return state
      }
      const targetAnswerId = action.answerId
      const targetAnswer = targetQuestion.answers.get(targetAnswerId)
      const targetNewScore = action.newScore
      if (!targetAnswer) {
        console.error('No target answer found when doing select correct answer update.', targetAnswerId, state, action)
        return state
      } else if ((targetAnswer.score === targetNewScore) && (targetAnswer.correct === targetNewScore)) {
        console.error(
          'Target answer already matches expected values when doing select correct answer update.',
          targetAnswer,
          targetNewScore,
          state,
          action
        )
        return state
      }
      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      const newAnswers = new Map()

      for (const answer of targetQuestion.answers.values()) {
        const newAnswer = {
          ...answer,
          score: (answer.id === targetAnswerId ? targetNewScore : 0),
          correct: (answer.id === targetAnswerId ? targetNewScore : 0),
          knockoutValue: (answer.id === targetAnswerId ? null : answer.knockoutValue),
          tag: answer.tag
        }
        newAnswers.set(answer.id, newAnswer)
      }
      const newQuestion = { ...targetQuestion, answers: newAnswers, isScored: 1 }
      newState.questions.set(targetQuestionId, newQuestion)

      return newState
    }
    case QuestionStateUpdate.SetAnswerScore: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      if (!targetQuestion) {
        console.error('No target question found when doing answer score update.', targetQuestionId, state, action)
        return state
      }
      const targetAnswerId = action.answerId
      const targetAnswer = targetQuestion.answers.get(targetAnswerId)
      const targetNewScore = action.newScore
      if (!targetAnswer) {
        console.error('No target answer found when doing answer score update.', targetAnswerId, state, action)
        return state
      } else if ((targetAnswer.score === targetNewScore) && (targetAnswer.correct === targetNewScore)) {
        console.error(
          'Target answer already matches expected values when doing answer score update.',
          targetAnswer,
          targetNewScore,
          state,
          action
        )
        return state
      }
      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      const newAnswers = new Map(targetQuestion.answers)
      newAnswers.set(targetAnswerId, { ...targetAnswer, score: targetNewScore })

      const newQuestion = { ...targetQuestion, answers: newAnswers, isScored: 1 }
      newState.questions.set(targetQuestionId, newQuestion)

      return newState
    }
    case QuestionStateUpdate.SaveNewAnswerTag: {
      const targetQuestionId = action.questionId
      const targetQuestion = state.questions.get(targetQuestionId)
      if (!targetQuestion) {
        console.error('No target question found when doing answer tag update.', targetQuestionId, state, action)
        return state
      }
      const targetAnswerId = action.answerId
      const targetAnswer = targetQuestion.answers.get(targetAnswerId)
      const newTag = {
        name: stripContentTags(targetQuestion.content),
        description: targetAnswer.content,
        color: '#868e96',
        icon: TagIcon.Flag,
        clientVisible: true
      }

      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      const newAnswers = new Map(targetQuestion.answers)
      newAnswers.set(targetAnswerId, { ...targetAnswer, tag: newTag })

      const newQuestion = { ...targetQuestion, answers: newAnswers }
      newState.questions.set(targetQuestionId, newQuestion)

      return newState
    }
    case QuestionStateUpdate.MediaPopupSelect: {
      const targetQuestionId = action.questionId ?? state.activeQuestionId
      const targetQuestion = state.questions.get(targetQuestionId)
      if (!targetQuestion || (Object.is(action.newMedia, targetQuestion.media))) {
        if (!targetQuestion) {
          console.error('No target question found when doing MediaPopupSelect update.', targetQuestionId, state, action)
        }
        return state
      }
      const newState = {
        ...state,
        questions: new Map(state.questions)
      }
      const newQuestion = { ...targetQuestion, media: action.newMedia }
      newState.questions.set(targetQuestionId, newQuestion)
      return newState
    }
    case QuestionStateUpdate.ImportQuestions: { // TODO [full redux] also map logic ids to their own new ids so that importing logic into an assessment which already has copies of that logic does not trigger a redux error.
      const importedQuestions = action.newQuestions
      const preservePageBreak = action.preservePageBreaks ?? false
      const newQuestionIdMap = new Map(state.importedQuestionIdMap)
      const newAnswerIdMap = new Map(state.importedAnswerIdMap)
      const newQuestions = new Map(state.questions)
      let nextFakeId = state.nextFakeId
      let newAnswerCount = 0
      let newQuestionCount = 0
      const lastPreExistingQuestion = state.questions.size ? Array.from(state.questions.values())[state.questions.size - 1] : null
      let nonNumberedQuestionCount = lastPreExistingQuestion ? (lastPreExistingQuestion.position + 1) - lastPreExistingQuestion.number : 0

      const addQuestionToMap = (importedQuestion, newQuestionId) => {
        const newAnswers = new Map()
        for (const answer of importedQuestion.answers.values()) {
          const newAnswerId = newAnswerIdMap.has(answer.id) ? newAnswerIdMap.get(answer.id) : nextFakeId
          if (newAnswerId === nextFakeId) {
            newAnswerIdMap.set(answer.id, newAnswerId)
            nextFakeId -= 1
          }
          const newAnswer = { ...answer, id: newAnswerId, questionId: newQuestionId }
          newAnswers.set(newAnswerId, newAnswer)
          newAnswerCount += 1
        }
        newQuestionIdMap.set(importedQuestion.id, newQuestionId)
        const questionNumbered = isNumbered(importedQuestion.type)
        if (!questionNumbered) {
          nonNumberedQuestionCount += 1
        }
        return {
          ...importedQuestion,
          position: newQuestions.size,
          number: (newQuestions.size + 1) - nonNumberedQuestionCount,
          answers: newAnswers,
          id: newQuestionId,
          directLinkId: importedQuestion.directLinkId ? importedQuestion.directLinkId : importedQuestion.id,
          variedLinkId: 0,
          pageBreak: (preservePageBreak ? importedQuestion.pageBreak : 0)
        }
      }

      const importedQuestionIdsWithLogic = []
      for (const question of importedQuestions.values()) {
        if ((!newQuestionIdMap.has(question.id)) || (!newQuestions.has(newQuestionIdMap.get(question.id)))) {
          const newQuestionId = newQuestionIdMap.get(question.id) ?? nextFakeId
          if (newQuestionId === nextFakeId) {
            newQuestionIdMap.set(question.id, newQuestionId)
            nextFakeId -= 1
          }
          newQuestions.set(newQuestionId, addQuestionToMap(question, newQuestionId))
          if (question.logic?.length) {
            importedQuestionIdsWithLogic.push(newQuestionId)
          }
          newQuestionCount += 1
        }
      }

      const correctLogicQuestionIds = (node) => {
        return {
          ...node,
          ...(!!node.questionId && { questionId: newQuestionIdMap.get(node.questionId) ?? node.questionId }),
          ...(!!node.parentQuestionId && { parentQuestionId: newQuestionIdMap.get(node.parentQuestionId) ?? node.parentQuestionId }),
          children: node.children?.map((child) => correctLogicQuestionIds(child)) ?? []
        }
      }
      for (const newQuestionId of importedQuestionIdsWithLogic) {
        const newQuestionWithLogic = newQuestions.get(newQuestionId)
        newQuestionWithLogic.logic = newQuestionWithLogic.logic.map((logic) => correctLogicQuestionIds(logic))
      }

      const makeNotificationId = (baseId) => [baseId, importedQuestions.size, newQuestionCount, newAnswerCount, nextFakeId].join('-')

      if (!newQuestionCount) {
        notifications.show(
          {
            id: makeNotificationId('import-not-completed'),
            color: 'yellow',
            title: 'Import Empty',
            message: 'There were no new questions to import among the ' + importedQuestions.size + ' question' + ((importedQuestions.size === 1) ? '' : 's') + ' selected.'
          }
        )
        console.warn('Attempted to import questions which were all already found imported - skipping.', action, state)
        return state
      }
      notifications.show(
        {
          id: makeNotificationId('import-completed'),
          color: 'green',
          title: 'Import Completed',
          message: 'Imported ' + newQuestionCount + ' new question' + (newQuestionCount > 1 ? 's' : '') + ' with ' + (newAnswerCount || 'no') + ' answer' + (newAnswerCount === 1 ? '' : 's') + '!' + (newAnswerCount ? '' : ' Talk about a philosophical assessment. 😅')
        }
      )
      return {
        ...state,
        questions: newQuestions,
        importedQuestionIdMap: newQuestionIdMap,
        importedAnswerIdMap: newAnswerIdMap,
        nextFakeId: nextFakeId
      }
    }
    default: {
      console.error('Unknown question state update action type - skipping update.', action, state)
      return state
    }
  }
}

function setStateQuestionAnswers (state, question, newAnswers) {
  const newState = {
    ...state,
    questions: new Map(state.questions)
  }
  newState.questions.set(question.id, {
    ...question,
    answers: newAnswers
  })
  return newState
}

export {
  createInitialQuestionsState,
  formatQuestionsFromJson,
  questionsReducer,
  QuestionStateUpdate,
  RandomizedPoolEvent
}
